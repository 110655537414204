import React, { useCallback, useEffect, useState } from 'react'
import Context from './context'
import type { ProviderProps } from './types'
import { AssigneeResponse } from '@rocket-mono/types'
import { useAstro } from '../../AstroProvider'

export const WorkAssigneeProvider: React.FC<ProviderProps> = ({
  domain: relatedDomain,
  domainId,
  children,
}: ProviderProps) => {
  const { astro } = useAstro()
  const [assigneeList, setAssigneeList] = useState<AssigneeResponse[]>([])

  const createAssignee = useCallback(
    (relatedDomainId: string, { userId, userEmail }: { userId?: number; userEmail?: string }) => {
      astro.createAssignee({ userId, userEmail, relatedDomain, relatedDomainId })
    },
    [],
  )
  const deleteAssignee = useCallback((deleteIds: { id: string }[]) => {
    astro.deleteAssignees(deleteIds)
  }, [])

  useEffect(() => {
    if (domainId)
      astro
        .readAssignee(relatedDomain, domainId)
        .then(setAssigneeList)
        .catch(() => setAssigneeList([]))
  }, [relatedDomain, domainId])

  useEffect(() => {
    console.debug('provider-WorkAssigneeProvider', { assigneeList })
  }, [assigneeList])

  return <Context.Provider value={{ assigneeList, createAssignee, deleteAssignee }}>{children}</Context.Provider>
}

export * from './hooks'
