import TextPlane from './Text'
import TextArea from './TextArea'
import TextField from './TextField'

const Text = Object.assign(TextPlane, {
  Area: TextArea,
  Field: TextField,
})

export { Text, TextArea, TextField }
