import React from 'react'
import Context from './context'
import type { SignpadContextProps } from './types'

export const useSignpad = () => {
  const context = React.useContext<SignpadContextProps | undefined>(Context)
  if (context === undefined) throw new Error('There must be a SignpadProvider as Ancestor of all Hooks and HOCs')

  return context
}
