import React from 'react'

import Context from './context'

import type { SigningCampaignContextProps } from './types'

export const useSigningCampaign = (letterId: string) => {
  const context = React.useContext<SigningCampaignContextProps | undefined>(Context)

  if (context === undefined)
    throw new Error('There must be a SigningCampaignProvider as Ancestor of all Hooks and HOCs')

  return context
}
