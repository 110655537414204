import React, { useState } from 'react'

import { COLOR } from '@rocket-mono/libs'
import { NativeSyntheticEvent, StyleSheet, TextInput, TextInputKeyPressEventData } from 'react-native'

export interface TextFieldProps {
  value: string
  placeholder: string
  disabled?: boolean
  secureTextEntry?: boolean
  onChangeValue?: (value: string) => void
  onSubmitEditing?: () => void
  onKeyPress?: (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => void
}

const TextField: React.FC<TextFieldProps> = ({
  value,
  placeholder,
  disabled,
  secureTextEntry,
  onChangeValue,
  onSubmitEditing,
  onKeyPress,
}) => {
  const [isFocus, setIsFocus] = useState(false)
  return (
    <TextInput
      value={value}
      secureTextEntry={secureTextEntry}
      placeholder={placeholder}
      placeholderTextColor={COLOR.gray.g400}
      onChangeText={(text) => onChangeValue && onChangeValue(text)}
      editable={disabled ? false : true}
      selectTextOnFocus={disabled ? false : true}
      onFocus={() => setIsFocus(true)}
      onBlur={() => setIsFocus(false)}
      onKeyPress={onKeyPress}
      style={[styles.commonStyle, isFocus && styles.focusStyle, disabled && styles.disableStyle]}
      onSubmitEditing={onSubmitEditing}
      blurOnSubmit={false}
    />
  )
}

export default TextField

const styles = StyleSheet.create({
  commonStyle: {
    height: 36,
    padding: 8,
    backgroundColor: COLOR.mono.white,
    borderWidth: 1,
    borderRadius: 4,
    borderColor: COLOR.gray.g200,
  },
  focusStyle: {
    backgroundColor: COLOR.gray.g050,
    borderColor: COLOR.gray.g300,
  },
  disableStyle: {
    backgroundColor: COLOR.opacity.blk10,
    borderColor: COLOR.gray.g100,
    color: COLOR.gray.g400,
  },
})
