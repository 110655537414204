import * as React from 'react'

import type { AstroOption } from '@rocket-mono/types'
import { ContextProps } from './types'

export const AstroContext = React.createContext<ContextProps | undefined>(undefined)
interface Props {
  token: string
  option: AstroOption
  children?: JSX.Element | JSX.Element[]
}

export const AstroProvider = ({ token, option, children }: Props) => {
  return <AstroContext.Provider value={{ option, token }}>{children}</AstroContext.Provider>
}
export * from './hooks'
