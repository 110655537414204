import * as React from 'react'

import { View, Text, StyleSheet, Pressable } from 'react-native'
import { Tooltip } from '@rocket/components'
import { COLOR, FONT, IColors } from '@rocket-mono/libs'
import { FAIcon, WorkIcon, BoardLabel } from '@rocket-atoms/icon'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

interface Props {
  crumb1Text: string
  crumb1WorkType?: string
  crumb1BoardType?: IColors
  crumb1BeforeIconName?: IconProp
  crumb1AterIconName?: IconProp
  crumb2Text?: string
  crumb2WorkType?: string
  crumb2BoardType?: IColors
  crumb2BeforeIconName?: IconProp
  crumb2AterIconName?: IconProp
  crumb3Text?: string
  crumb3BeforeIconName?: IconProp
  crumb3AterIconName?: IconProp
}
const Breadcrumbs = ({
  crumb1Text,
  crumb1WorkType,
  crumb1BoardType,
  crumb1BeforeIconName,
  crumb1AterIconName,
  crumb2Text,
  crumb2WorkType,
  crumb2BoardType,
  crumb2BeforeIconName,
  crumb2AterIconName,
  crumb3Text,
  crumb3BeforeIconName,
  crumb3AterIconName,
}: Props) => {
  const [isHover, setIsHover] = React.useState({ crumb1: false, crumb2: false, crumb3: false })
  return (
    <View style={[styles.container, styles.flexBox]}>
      <Tooltip
        gab={0}
        darkType={true}
        direction={'bottom'}
        text={crumb1Text}
        onHoverIn={() => setIsHover({ ...isHover, crumb1: true })}
        onHoverOut={() => setIsHover({ ...isHover, crumb1: false })}
      >
        {(props) => (
          <Pressable {...props} style={styles.flexBox}>
            {crumb1WorkType && <WorkIcon type={crumb1WorkType} size={16} />}
            {crumb1BoardType && <BoardLabel color={crumb1BoardType} size={16} />}
            {crumb1BeforeIconName && <FAIcon iconName={crumb1BeforeIconName} size={'xs'} color={'gray.g600'} />}
            <Text
              style={[styles.textStyle, isHover.crumb1 && styles.textHoverStyle]}
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {crumb1Text}
            </Text>
            {crumb1AterIconName && <FAIcon iconName={crumb1AterIconName} size={'xs'} color={'gray.g600'} />}
          </Pressable>
        )}
      </Tooltip>
      {crumb2Text && (
        <>
          <Text style={styles.divLine}>/</Text>
          <Tooltip
            gab={0}
            darkType={true}
            direction={'bottom'}
            text={crumb2Text}
            onHoverIn={() => setIsHover({ ...isHover, crumb2: true })}
            onHoverOut={() => setIsHover({ ...isHover, crumb2: false })}
          >
            {(props) => (
              <Pressable {...props} style={styles.flexBox}>
                {crumb2WorkType && <WorkIcon type={crumb2WorkType} size={16} />}
                {crumb2BoardType && <BoardLabel color={crumb2BoardType} size={16} />}
                {crumb2BeforeIconName && <FAIcon iconName={crumb2BeforeIconName} size={'xs'} color={'gray.g600'} />}
                <Text
                  style={[styles.textStyle, isHover.crumb2 && styles.textHoverStyle]}
                  numberOfLines={1}
                  ellipsizeMode="tail"
                >
                  {crumb2Text}
                </Text>
                {crumb2AterIconName && <FAIcon iconName={crumb2AterIconName} size={'xs'} color={'gray.g600'} />}
              </Pressable>
            )}
          </Tooltip>
        </>
      )}
      {crumb3Text && (
        <>
          <Text style={styles.divLine}>/</Text>
          <Tooltip
            gab={0}
            darkType={true}
            direction={'bottom'}
            text={crumb3Text}
            onHoverIn={() => setIsHover({ ...isHover, crumb3: true })}
            onHoverOut={() => setIsHover({ ...isHover, crumb3: false })}
          >
            {(props) => (
              <Pressable {...props} style={styles.flexBox}>
                {crumb3BeforeIconName && <FAIcon iconName={crumb3BeforeIconName} size={'xs'} color={'gray.g600'} />}
                <Text
                  style={[styles.textStyle, isHover.crumb3 && styles.textHoverStyle]}
                  numberOfLines={1}
                  ellipsizeMode="tail"
                >
                  {crumb3Text}
                </Text>
                {crumb3AterIconName && <FAIcon iconName={crumb3AterIconName} size={'xs'} color={'gray.g600'} />}
              </Pressable>
            )}
          </Tooltip>
        </>
      )}
    </View>
  )
}

export default Breadcrumbs

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  flexBox: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  textStyle: {
    ...FONT.txtXs,
    color: COLOR.gray.g700,
    marginHorizontal: 4,
  },
  textHoverStyle: {
    textDecorationLine: 'underline',
  },
  divLine: {
    ...FONT.txtXs,
    marginRight: 4,
    color: COLOR.gray.g600,
  },
})
