import React from 'react'
import { AgreeScreen } from '@rocket-mono/screen-workspace'
import { useNavigate, useParams } from 'react-router-dom'
import { SignpadProvider } from '@rocket-mono/providers'

const LetterAgreementPage: React.FC = () => {
  const navigate = useNavigate()
  const { letterId } = useParams()

  return (
    <SignpadProvider>
      <AgreeScreen
        letterId={letterId || ''}
        onSubmit={() => {
          navigate('/submitted?letterId=' + letterId)
        }}
        onError={(formState: string) => {
          console.debug('[signpad-web] onError', { formState })
          switch (formState) {
            case 'DUPLICATED':
              navigate('/duplicated')
              break
            case 'EXPIRED':
              navigate('/expired')
              break
            default:
              navigate('/expired')
              break
          }
        }}
      />
    </SignpadProvider>
  )
}

export default LetterAgreementPage
