import { i18nInstance } from '@rocket-mono/libs'
import { useKakaotalkInApp, useSigningCampaign } from '@rocket-mono/providers'
import { SubmitScreen } from '@rocket-mono/screen-workspace'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

const LetterSubmittedPage: React.FC = () => {
  const { closeAppWindow } = useKakaotalkInApp()
  const { t } = useTranslation('translation', { i18n: i18nInstance })
  const [searchParams, setSearchParams] = useSearchParams()
  const [letterId, setLetterId] = React.useState<string | null>(null)
  const { fetchLetter } = useSigningCampaign(letterId || '')
  const [campaignTitle, setCampaignTitle] = React.useState<string>('')

  React.useEffect(() => {
    setLetterId(searchParams.get('letterId'))
  }, [searchParams])

  React.useEffect(() => {
    if (letterId) {
      fetchLetter(letterId)
    }
  }, [letterId])

  return (
    <SubmitScreen
      campaignTitle={campaignTitle}
      // formTitle={t(`campaign.agree.back`)}
      formTitle="전자서명에 동의완료"
      onPressClose={() => {
        closeAppWindow()
      }}
    />
  )
}

export default LetterSubmittedPage
