'use client'
"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.tsx
var src_exports = {};
__export(src_exports, {
  Badge: () => Badge_default,
  Button: () => Button_default,
  ButtonWideFooter: () => ButtonWideFooter_default,
  Checkbox: () => Checkbox_default,
  ClickOutside: () => ClickOutside_default,
  DialogBottomView: () => view_default2,
  DialogView: () => view_default,
  DivLine: () => DivLine_default,
  IconButton: () => IconButton_default,
  Image: () => Image_default,
  Modal: () => Modal_default,
  RichTextInput: () => RichTextInput_default,
  Select: () => Select_default,
  SkeletonBar: () => SkeletonBar,
  SkeletonCircle: () => SkeletonCircle,
  SkeletonSquare: () => SkeletonSquare,
  Text: () => Text_default,
  TextInput: () => TextInput_default,
  View: () => View_default
});
module.exports = __toCommonJS(src_exports);

// src/Badge/index.tsx
var React3 = __toESM(require("react"));
var import_react_native3 = require("react-native");
var import_libs2 = require("@rocket-mono/libs");

// src/View/index.tsx
var React = __toESM(require("react"));
var import_react_native = require("react-native");
var import_jsx_runtime = require("react/jsx-runtime");
var View = React.forwardRef(({ children, ...viewProps }, ref) => {
  return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_react_native.View, { style: { zIndex: void 0 }, ref, ...viewProps, children });
});
var View_default = View;

// src/Text/index.tsx
var React2 = __toESM(require("react"));
var import_react_native2 = require("react-native");
var import_libs = require("@rocket-mono/libs");
var import_jsx_runtime2 = require("react/jsx-runtime");
var Text = ({
  children,
  fontName = "textRegular",
  fontColor = "mono.black",
  style,
  ...textProps
}) => {
  return /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(import_react_native2.Text, { ...textProps, style: [(0, import_libs.getFontStyle)(fontName), { color: (0, import_libs.getRealColor)(fontColor) }, style], children });
};
var Text_default = React2.memo(Text);

// src/Badge/index.tsx
var import_jsx_runtime3 = require("react/jsx-runtime");
var Badge = ({ type = "DEFAULT", count }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(
    View_default,
    {
      style: [
        styles.container,
        type === "DEFAULT" && { backgroundColor: import_libs2.COLOR.main.red },
        type === "PRIMARY" && { backgroundColor: import_libs2.COLOR.primary.blue500 },
        type === "ADDED" && { backgroundColor: import_libs2.COLOR.gray.g050 },
        type === "REMOVED" && { backgroundColor: import_libs2.COLOR.gray.g050 }
      ],
      children: /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(
        Text_default,
        {
          fontName: "txt2XsBold",
          fontColor: "mono.white",
          style: [
            type === "ADDED" && { backgroundColor: import_libs2.COLOR.primary.blue500 },
            type === "REMOVED" && { backgroundColor: import_libs2.COLOR.main.red }
          ],
          children: count
        }
      )
    }
  );
};
var Badge_default = React3.memo(Badge);
var styles = import_react_native3.StyleSheet.create({
  container: {
    paddingHorizontal: 4,
    minWidth: 16,
    height: 16,
    borderRadius: 20
  }
});

// src/Button/index.tsx
var import_libs3 = require("@rocket-mono/libs");
var React4 = __toESM(require("react"));
var import_react_native4 = require("react-native");
var import_jsx_runtime4 = require("react/jsx-runtime");
var Button = ({
  size = "medium",
  color = "mono.white",
  backgroundColor = "main.blue",
  borderColor = "transparent",
  borderStyle = "solid",
  rounded = false,
  wide = false,
  onPressIn,
  onPressOut,
  style,
  hoverStyle,
  pressedStyle,
  disabledStyle,
  disabled,
  children,
  ...props
}) => {
  const _onPressIn = React4.useCallback(
    (event) => {
      onPressIn && onPressIn(event);
    },
    [onPressIn]
  );
  const _onPressOut = React4.useCallback(
    (event) => {
      onPressOut && onPressOut(event);
    },
    [onPressOut]
  );
  const onStyle = React4.useCallback(
    // @ts-ignore hovered only used in web
    ({ hovered, pressed }) => {
      return [
        styles2.pressable,
        {
          ...getDimensions(size, wide),
          borderRadius: rounded ? geRoundedBoarderRadius(getDimensions(size, wide)) : getBorderRadius(getDimensions(size, wide)),
          backgroundColor: (0, import_libs3.getRealColor)(backgroundColor),
          borderColor: (0, import_libs3.getRealColor)(borderColor),
          borderStyle
        },
        style,
        !disabled && hovered && (hoverStyle ?? styles2.hover),
        !disabled && pressed && (pressedStyle ?? styles2.pressed),
        disabled && (disabledStyle ?? styles2.disabled)
      ];
    },
    [
      size,
      wide,
      rounded,
      backgroundColor,
      borderColor,
      borderStyle,
      style,
      hoverStyle,
      pressedStyle,
      disabledStyle,
      disabled
    ]
  );
  return /* @__PURE__ */ (0, import_jsx_runtime4.jsx)(
    View_default,
    {
      style: [disabled && import_react_native4.Platform.select({ web: { cursor: "not-allowed" } })],
      children: /* @__PURE__ */ (0, import_jsx_runtime4.jsx)(import_react_native4.Pressable, { style: onStyle, onPressIn: _onPressIn, onPressOut: _onPressOut, disabled, ...props, children: printContent({ children, color }) })
    }
  );
};
var Button_default = Button;
var printContent = ({
  children,
  color
}) => {
  if (typeof children === "string") {
    return /* @__PURE__ */ (0, import_jsx_runtime4.jsx)(Text_default, { fontName: "textRegular", fontColor: color, selectable: false, children });
  }
  return children;
};
var getDimensions = (size, wide) => {
  const CONTAINER_SIZE = {
    tiny: {
      height: 26,
      minWidth: 26
    },
    small: {
      height: 28,
      minWidth: 40
    },
    medium: {
      height: 33,
      minWidth: 60
    },
    large: {
      height: 36,
      minWidth: 100
    },
    xlarge: {
      height: 51,
      minWidth: 224
    }
  };
  if (wide) {
    return {
      height: CONTAINER_SIZE[size].height,
      minWidth: "100%"
    };
  }
  return CONTAINER_SIZE[size];
};
var geRoundedBoarderRadius = ({ minWidth, height }) => {
  if (typeof minWidth === "string" || typeof height === "string") {
    return 9999;
  }
  return Math.max(minWidth, height);
};
var getBorderRadius = ({ minWidth, height }) => {
  if (typeof minWidth === "string" || typeof height === "string") {
    return 5;
  }
  return Math.min(minWidth, height) * 0.14;
};
var styles2 = import_react_native4.StyleSheet.create({
  pressable: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "flex-start",
    borderWidth: 1
  },
  hover: {
    opacity: 0.8
  },
  pressed: {
    opacity: 0.5
  },
  disabled: {
    backgroundColor: import_libs3.COLOR.mono.lightGray
  }
});

// src/DivLine/index.tsx
var import_libs4 = require("@rocket-mono/libs");
var import_react_native5 = require("react-native");
var import_jsx_runtime5 = require("react/jsx-runtime");
var DivLine = ({ theme, height, marginHorizontal }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(
    import_react_native5.View,
    {
      style: [
        styles3.style,
        { height, marginHorizontal },
        theme === "dark" && { backgroundColor: import_libs4.COLOR.opacity.w10 }
      ]
    }
  );
};
var DivLine_default = DivLine;
var styles3 = import_react_native5.StyleSheet.create({
  style: {
    width: 1,
    backgroundColor: "rgba(0, 0, 0, 0.1)"
  }
});

// src/Image/index.tsx
var React5 = __toESM(require("react"));
var import_react_native6 = require("react-native");
var import_jsx_runtime6 = require("react/jsx-runtime");
var Image = ({ containerStyle, style, size, ...imageProps }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime6.jsx)(import_react_native6.View, { style: containerStyle, children: /* @__PURE__ */ (0, import_jsx_runtime6.jsx)(import_react_native6.Image, { ...imageProps, style: [style, size] }) });
};
var Image_default = React5.memo(Image);

// src/Modal/Modal.Base.tsx
var import_react_native7 = require("react-native");

// src/ClickOutside/index.tsx
var import_react = __toESM(require("react"));
var import_jsx_runtime7 = require("react/jsx-runtime");
var ClickOutside = ({ id, children, onClickOutside }) => {
  const ref = import_react.default.useRef(null);
  const handleClickOutside = (event) => {
    const mouseEvent = event;
    if (ref.current && !ref.current.contains(mouseEvent.target)) {
      onClickOutside(mouseEvent);
    }
  };
  import_react.default.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
  return /* @__PURE__ */ (0, import_jsx_runtime7.jsx)("div", { ref, id, style: { width: "100%", height: "100%" }, children });
};
var ClickOutside_default = ClickOutside;

// src/Modal/Modal.Base.tsx
var import_jsx_runtime8 = require("react/jsx-runtime");
var ModalBase = ({
  containerStyle,
  modalType = "center",
  animationType = "slide",
  borderType = "round",
  visible,
  children,
  onDismiss
}) => {
  const { width } = (0, import_react_native7.useWindowDimensions)();
  return /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(import_react_native7.Modal, { transparent: true, animationType, visible, children: /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(
    View_default,
    {
      style: [
        styles4.containerView,
        modalType === "full" && { width: "100%" },
        modalType === "right" && { alignItems: "flex-end" },
        modalType === "center" && { alignItems: "center" }
      ],
      children: /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(
        View_default,
        {
          style: [
            styles4.modalView,
            modalType === "center" && { alignItems: "center" },
            borderType === "round" && {
              borderRadius: 8
            },
            560 > width && { width: "95%", minWidth: "95%" },
            containerStyle
          ],
          children: /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(ClickOutside_default, { onClickOutside: () => onDismiss && onDismiss(), children })
        }
      )
    }
  ) });
};
ModalBase.displayName = "Modal.Base";
var styles4 = import_react_native7.StyleSheet.create({
  containerView: {
    flex: 1,
    justifyContent: "center"
  },
  modalView: {
    minWidth: 300,
    // maxHeight: 300,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5
  }
});

// src/Modal/Modal.Body.tsx
var import_react_native8 = require("react-native");
var import_jsx_runtime9 = require("react/jsx-runtime");
var ModalBody = ({ style, innerStyle, children, isDirect = false, maxHeight, contentContainerStyle }) => {
  const { height: windowHeight } = (0, import_react_native8.useWindowDimensions)();
  return isDirect ? /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(View_default, { style: [styles5.contentsView, style], children }) : /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(import_react_native8.ScrollView, { style: [styles5.contentsView, style], contentContainerStyle, children: /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(View_default, { style: [maxHeight ? { maxHeight } : { maxHeight: windowHeight - 200 }, innerStyle], children }) });
};
ModalBody.displayName = "Modal.Body";
var styles5 = import_react_native8.StyleSheet.create({
  contentsView: {
    flex: 1,
    padding: 24
  }
});

// src/Modal/Modal.Footer.tsx
var import_react_native9 = require("react-native");
var import_jsx_runtime10 = require("react/jsx-runtime");
var ModalFooter = ({ height = 50, children }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(View_default, { style: [styles6.container, { height }], children });
};
ModalFooter.displayName = "Modal.Footer";
var styles6 = import_react_native9.StyleSheet.create({
  container: {
    width: "100%",
    height: 50,
    flexDirection: "row",
    alignItems: "flex-end",
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8
  }
});

// src/Modal/Modal.Header.tsx
var import_react_native11 = require("react-native");
var import_libs6 = require("@rocket-mono/libs");

// src/ButtonIcon/index.tsx
var import_react2 = require("react");
var import_react_native10 = require("react-native");
var import_libs5 = require("@rocket-mono/libs");
var import_jsx_runtime11 = require("react/jsx-runtime");
var getBorderRadius2 = (containerSize) => {
  return containerSize * 0.2;
};
var ButtonIcon = ({
  containerSize = 35,
  icon,
  // iconName,
  // iconSize = containerSize * 0.5,
  // iconColor = 'mono.black',
  // iconViewbox = '0 -40 1000 1000',
  backgroundColor = "transparent",
  rounded = false,
  onPressIn,
  onPressOut,
  style,
  hoverStyle,
  pressedStyle,
  disabledStyle,
  badgeCount = 0,
  disabled,
  ...props
}) => {
  const _onPressIn = (0, import_react2.useCallback)(
    (event) => {
      onPressIn && onPressIn(event);
    },
    [onPressIn]
  );
  const _onPressOut = (0, import_react2.useCallback)(
    (event) => {
      onPressOut && onPressOut(event);
    },
    [onPressOut]
  );
  const onStyle = (0, import_react2.useCallback)(
    // @ts-ignore hovered only used in web
    ({ hovered, pressed }) => {
      return [
        styles7.pressable,
        {
          borderRadius: rounded ? containerSize : getBorderRadius2(containerSize),
          backgroundColor: (0, import_libs5.getRealColor)(backgroundColor)
        },
        !disabled && hovered && (hoverStyle ?? styles7.hover),
        !disabled && pressed && (pressedStyle ?? styles7.pressed),
        disabled && (disabledStyle ?? styles7.disabled)
      ];
    },
    [containerSize, rounded, backgroundColor, hoverStyle, pressedStyle, disabledStyle, disabled]
  );
  return /* @__PURE__ */ (0, import_jsx_runtime11.jsx)(
    View_default,
    {
      style: [
        styles7.container,
        {
          height: containerSize,
          width: containerSize,
          borderRadius: rounded ? containerSize : getBorderRadius2(containerSize)
        },
        style
      ],
      children: /* @__PURE__ */ (0, import_jsx_runtime11.jsxs)(import_react_native10.Pressable, { style: onStyle, onPressIn: _onPressIn, onPressOut: _onPressOut, disabled, ...props, children: [
        icon,
        badgeCount > 0 && /* @__PURE__ */ (0, import_jsx_runtime11.jsx)(View_default, { style: styles7.iconCountContainer, children: /* @__PURE__ */ (0, import_jsx_runtime11.jsx)(Text_default, { fontName: "textRegular", fontColor: "mono.white", children: badgeCount }) })
      ] })
    }
  );
};
var styles7 = import_react_native10.StyleSheet.create({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "stretch"
  },
  pressable: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "stretch",
    height: "100%",
    width: "100%",
    ...import_react_native10.Platform.select({
      web: {
        outlineWidth: 0
      }
    })
  },
  hover: {
    opacity: 0.8
  },
  pressed: {
    opacity: 0.5
  },
  disabled: {
    backgroundColor: import_libs5.COLOR.mono.lightGray
  },
  iconCountContainer: {
    backgroundColor: import_libs5.COLOR.main.red,
    borderRadius: 15,
    width: 20,
    height: 20,
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    right: 0
  }
});
var ButtonIcon_default = ButtonIcon;

// src/Modal/Modal.Header.tsx
var import_jsx_runtime12 = require("react/jsx-runtime");
var ModalHeader = ({
  children,
  textAlign,
  height = 56,
  // onPressBack,
  closeIcon,
  onPressClose
}) => {
  return /* @__PURE__ */ (0, import_jsx_runtime12.jsxs)(View_default, { style: [styles8.headerView, { height }], children: [
    typeof children === "string" ? /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(Text_default, { style: { ...styles8.text, textAlign }, fontName: "subTitleBold", fontColor: "gray.g900", children }) : /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(import_jsx_runtime12.Fragment, { children }),
    closeIcon && /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(
      ButtonIcon_default,
      {
        style: styles8.closeIcon,
        icon: closeIcon,
        containerSize: 52,
        onPress: onPressClose
      }
    )
  ] });
};
ModalHeader.displayName = "Modal.Header";
var styles8 = import_react_native11.StyleSheet.create({
  headerView: {
    width: "100%",
    paddingHorizontal: 24,
    flexDirection: "row",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: import_libs6.COLOR.gray.g150,
    ...import_react_native11.Platform.select({
      web: {
        outline: "none"
      }
    })
  },
  text: {
    flex: 1
  },
  backIcon: {
    position: "absolute",
    left: 0
  },
  closeIcon: {
    position: "absolute",
    right: 0
  }
});

// src/Modal/index.tsx
var Modal2 = Object.assign(ModalBase, {
  Body: ModalBody,
  Footer: ModalFooter,
  Header: ModalHeader
});
var Modal_default = Modal2;

// src/ModalDialog/view.tsx
var React7 = __toESM(require("react"));
var import_react_native13 = require("react-native");

// src/ModalDialog/button.tsx
var import_react_native12 = require("react-native");
var import_libs7 = require("@rocket-mono/libs");
var import_jsx_runtime13 = require("react/jsx-runtime");
var DialogButton = ({ name, buttonColor, onPress }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime13.jsx)(
    Button_default,
    {
      onPress,
      backgroundColor: buttonColor,
      borderColor: buttonColor === "mono.white" ? "mono.gray" : void 0,
      size: "xlarge",
      style: [
        styles9.button,
        {
          ...import_react_native12.Platform.select({
            native: {
              height: 44
            }
          })
        }
      ],
      children: /* @__PURE__ */ (0, import_jsx_runtime13.jsx)(
        Text_default,
        {
          fontColor: buttonColor === "mono.white" ? "mono.black" : "mono.white",
          style: {
            ...import_react_native12.Platform.select({
              web: {
                ...import_libs7.FONT.H7Medium
              },
              native: {
                ...import_libs7.FONT.txtMd
              }
            })
          },
          children: name
        }
      )
    }
  );
};
var styles9 = import_react_native12.StyleSheet.create({
  button: {
    minWidth: "100%",
    paddingHorizontal: 12,
    paddingVertical: 7,
    borderRadius: 40,
    marginVertical: 4,
    color: import_libs7.COLOR.mono.white
  },
  buttonCancel: {
    width: "100%",
    marginTop: 12
  }
});

// src/ModalDialog/view.tsx
var import_libs8 = require("@rocket-mono/libs");
var import_jsx_runtime14 = require("react/jsx-runtime");
var DialogView = ({
  visible,
  title,
  titleRender,
  message,
  messageRender,
  cancelText,
  onCancel,
  onClose,
  onDismiss,
  list,
  subList,
  children
}) => {
  const [top, setTop] = React7.useState(0);
  const dimensions = import_react_native13.Dimensions.get("window");
  const onLayout = React7.useCallback(
    (e) => {
      const { layout } = e.nativeEvent;
      setTop((dimensions.height - layout.height) / 2);
    },
    [dimensions]
  );
  return /* @__PURE__ */ (0, import_jsx_runtime14.jsxs)(import_react_native13.Modal, { animationType: "fade", transparent: true, visible, children: [
    import_react_native13.Platform.OS !== "web" && /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(
      import_react_native13.View,
      {
        style: {
          position: "absolute",
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          backgroundColor: import_libs8.COLOR.mono.black,
          opacity: 0.7,
          zIndex: 999
        }
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(
      import_react_native13.View,
      {
        onLayout,
        style: [
          styles10.container,
          { top, alignSelf: "center", zIndex: 10 }
          // Platform.OS === 'web' && {
          //   height,
          // },
        ],
        children: /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(import_react_native13.View, { style: { overflow: "hidden", paddingTop: 5 }, children: /* @__PURE__ */ (0, import_jsx_runtime14.jsxs)(import_react_native13.View, { style: [styles10.modalView], children: [
          /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(
            import_react_native13.View,
            {
              style: [
                styles10.headerContainer,
                {
                  width: 35
                }
              ]
            }
          ),
          /* @__PURE__ */ (0, import_jsx_runtime14.jsxs)(import_react_native13.View, { style: [styles10.messageTextContainer, children && { minHeight: "auto", paddingVertical: 12 }], children: [
            /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(import_react_native13.Text, { style: styles10.messageTitleText, children: titleRender ? titleRender() : title }),
            /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(import_react_native13.Text, { style: styles10.messageText, children: messageRender ? messageRender() : message })
          ] }),
          /* @__PURE__ */ (0, import_jsx_runtime14.jsxs)(import_react_native13.View, { children: [
            /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(import_react_native13.View, { style: styles10.confirmButtonContainer, children: /* @__PURE__ */ (0, import_jsx_runtime14.jsxs)(import_jsx_runtime14.Fragment, { children: [
              children && /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(import_react_native13.View, { style: { minHeight: 160 }, children }),
              list && list.map((item, idx) => /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(
                DialogButton,
                {
                  buttonColor: "main.blue",
                  name: item.name,
                  onPress: () => {
                    onClose();
                    item.action && item.action();
                  }
                },
                `confirm-btn-${idx}`
              )),
              subList && subList && subList.map((item, idx) => /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(
                DialogButton,
                {
                  buttonColor: "mono.white",
                  name: item.name,
                  onPress: () => {
                    onClose();
                    item.action && item.action();
                  }
                },
                `subconfirm-btn-${idx}`
              ))
            ] }) }),
            cancelText && onCancel && /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(import_react_native13.View, { style: styles10.cancelButtonContainer, children: /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(
              Button_default,
              {
                onPress: () => {
                  onCancel();
                  onClose();
                },
                backgroundColor: "mono.white",
                color: "main.blue",
                style: styles10.buttonCancel,
                children: /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(import_react_native13.Text, { style: (styles10.buttonText, styles10.buttonCancelText), children: cancelText })
              }
            ) })
          ] })
        ] }) })
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(import_react_native13.Pressable, { style: { flex: 1 }, onPress: onDismiss })
  ] });
};
var view_default = DialogView;
var styles10 = import_react_native13.StyleSheet.create({
  container: {
    position: "absolute",
    flex: 1
  },
  modalView: {
    ...import_react_native13.Platform.select({
      web: {
        width: 375
      },
      native: {
        width: 340
      }
    }),
    // minHeight: 430,
    justifyContent: "space-between",
    paddingVertical: 28,
    paddingHorizontal: 24,
    borderRadius: 8,
    backgroundColor: import_libs8.COLOR.mono.white
  },
  headerContainer: {
    position: "absolute",
    top: 20,
    right: 16,
    zIndex: 9
  },
  confirmButtonContainer: {
    ...import_react_native13.Platform.select({
      web: {
        paddingBottom: 20
      },
      native: {
        paddingBottom: 10
      }
    })
  },
  cancelButtonContainer: {
    borderTopWidth: 1,
    borderTopColor: import_libs8.COLOR.mono.lightGray
  },
  messageTextContainer: {
    flex: 1,
    ...import_react_native13.Platform.select({
      web: {
        minHeight: 170
      },
      native: {
        minHeight: 140
      }
    }),
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 16,
    paddingHorizontal: 16,
    textAlign: "center"
  },
  messageTitleText: {
    ...import_react_native13.Platform.select({
      web: {
        ...import_libs8.FONT.H5Bold,
        marginBottom: 8
      },
      native: {
        ...import_libs8.FONT.H6Bold,
        marginBottom: 4
      }
    }),
    color: import_libs8.COLOR.mono.black
  },
  messageText: {
    ...import_react_native13.Platform.select({
      web: {
        ...import_libs8.FONT.txtMd
      },
      native: {
        ...import_libs8.FONT.txtSm
      }
    }),
    color: import_libs8.COLOR.mono.black
  },
  buttonCancel: {
    width: "100%",
    marginTop: 12
  },
  buttonCancelText: {
    ...import_react_native13.Platform.select({
      web: {
        ...import_libs8.FONT.txtMd
      },
      native: {
        ...import_libs8.FONT.txtSm
      }
    }),
    color: import_libs8.COLOR.main.blue
  },
  buttonText: {
    ...import_libs8.FONT.subTitleBold,
    fontWeight: "400",
    lineHeight: 19.24,
    color: import_libs8.COLOR.mono.black
  }
});

// src/ModalDialog/bottom/view.tsx
var import_react3 = require("react");
var import_react_native14 = require("react-native");
var import_libs9 = require("@rocket-mono/libs");
var import_atoms = require("@rocket/atoms");
var import_jsx_runtime15 = require("react/jsx-runtime");
var DialogBottomView = ({
  visible,
  title,
  cancelText,
  onCancel,
  onDismiss,
  list,
  subList,
  bottom
}) => {
  const [containerWidth, setContainerWidth] = (0, import_react3.useState)(0);
  const [buttonAreaWidth, setbuttonAreaWidth] = (0, import_react3.useState)(0);
  const [messageWidth, setMessageWidth] = (0, import_react3.useState)(0);
  const chartWidth = import_react_native14.Dimensions.get("window").width;
  const maxMessageWidth = 310;
  return /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(import_react_native14.Modal, { animationType: "slide", transparent: true, visible, children: /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(View_default, { style: styles11.container, children: /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(View_default, { style: { overflow: "hidden", paddingTop: 5 }, children: /* @__PURE__ */ (0, import_jsx_runtime15.jsxs)(
    View_default,
    {
      style: [
        styles11.modalView,
        { bottom },
        chartWidth > maxMessageWidth && messageWidth > maxMessageWidth && styles11.modalViewV2,
        containerWidth <= 400 && styles11.modalViewV2,
        buttonAreaWidth >= containerWidth / 2 && styles11.modalViewV2
      ],
      onLayout: (e) => {
        setContainerWidth(e.nativeEvent.layout.width);
      },
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(
          View_default,
          {
            style: [
              styles11.messageTextContainer,
              chartWidth > maxMessageWidth && messageWidth > maxMessageWidth && styles11.messageTextContainerV2,
              containerWidth <= 400 && styles11.messageTextContainerV2,
              buttonAreaWidth >= containerWidth / 2 && styles11.messageTextContainerV2
            ],
            children: /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(
              Text_default,
              {
                style: styles11.messageText,
                onLayout: (e) => {
                  setMessageWidth(e.nativeEvent.layout.width);
                },
                children: title
              }
            )
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime15.jsxs)(
          View_default,
          {
            style: [
              styles11.buttonContainerWrap,
              chartWidth > maxMessageWidth && messageWidth > maxMessageWidth && styles11.buttonContainerWrapV2,
              containerWidth <= 400 && styles11.buttonContainerWrapV2
            ],
            onLayout: (e) => {
              setbuttonAreaWidth(e.nativeEvent.layout.width);
            },
            children: [
              /* @__PURE__ */ (0, import_jsx_runtime15.jsxs)(View_default, { style: styles11.buttonContainer, children: [
                list && list.map((item, idx) => /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(Button_default, { onPress: item.action, style: [styles11.button, styles11.nextButton], children: /* @__PURE__ */ (0, import_jsx_runtime15.jsxs)(Text_default, { fontName: "subTextMedium", fontColor: "mono.white", children: [
                  item.icon && /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(View_default, { style: styles11.innericon, children: /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(import_atoms.FAIcon, { size: "sm", iconName: item.icon, color: "mono.white" }) }),
                  item.name
                ] }) }, `confirm-btn-${idx}`)),
                subList && subList.map((item, idx) => /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(
                  Button_default,
                  {
                    color: "mono.darkGray",
                    backgroundColor: "mono.lightGray",
                    onPress: item.action,
                    style: [styles11.button, styles11.nextButton],
                    children: /* @__PURE__ */ (0, import_jsx_runtime15.jsxs)(Text_default, { fontName: "subTextMedium", fontColor: "mono.darkGray", children: [
                      item.icon && /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(View_default, { style: styles11.innericon, children: /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(import_atoms.FAIcon, { size: "sm", iconName: item.icon, color: "mono.darkGray" }) }),
                      item.name
                    ] })
                  },
                  `btn-${idx}`
                ))
              ] }),
              onCancel && /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(View_default, { style: styles11.buttonContainer, children: /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(
                Button_default,
                {
                  style: styles11.button,
                  onPress: () => {
                    onCancel();
                    onDismiss();
                  },
                  backgroundColor: "transparent.base",
                  children: /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(Text_default, { fontName: "subTextMedium", fontColor: "gray.g600", children: cancelText || "\uCDE8\uC18C" })
                }
              ) })
            ]
          }
        )
      ]
    }
  ) }) }) });
};
var view_default2 = DialogBottomView;
var styles11 = import_react_native14.StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    maxWidth: 800,
    justifyContent: "flex-end",
    alignSelf: "center",
    margin: 20,
    paddingHorizontal: 10,
    paddingBottom: 30
  },
  modalView: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    borderRadius: 4,
    paddingHorizontal: 20,
    paddingVertical: 8,
    backgroundColor: (0, import_libs9.getRealColor)("mono.white")
  },
  modalViewV2: {
    flexDirection: "column"
  },
  buttonContainerWrap: {
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "flex-start"
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  buttonContainerWrapV2: {
    justifyContent: "flex-end",
    marginRight: -8
  },
  messageTextContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  messageTextContainerV2: {
    paddingVertical: 4,
    marginBottom: 8
  },
  messageText: {
    ...import_libs9.FONT.txtSm,
    lineHeight: 20.72,
    color: (0, import_libs9.getRealColor)("mono.black")
  },
  button: {
    paddingHorizontal: 12,
    paddingVertical: 7
  },
  innericon: {
    marginRight: 2
  },
  nextButton: {
    marginHorizontal: 5
  }
  // buttonText: {
  //   ...FONT.subTextMedium,
  //   fontWeight: '400',
  //   lineHeight: 19.24,
  //   color: getRealColor('mono.black'),
  // },
});

// src/RichTextInput/index.tsx
var React8 = __toESM(require("react"));
var import_react_native15 = require("react-native");
var import_libs10 = require("@rocket-mono/libs");
var import_jsx_runtime16 = require("react/jsx-runtime");
var RichTextInput = class extends React8.Component {
  get innerText() {
    throw new Error("Not implemented");
  }
  inputProps;
  inputRef;
  constructor(props) {
    super(props);
    this.inputProps = props;
  }
  render() {
    return /* @__PURE__ */ (0, import_jsx_runtime16.jsx)(
      View_default,
      {
        style: [
          styles12.container,
          this.props.borderType !== void 0 && this.props.borderType === "solid" && styles12.solidBorder
        ],
        children: /* @__PURE__ */ (0, import_jsx_runtime16.jsx)(
          import_react_native15.TextInput,
          {
            placeholderTextColor: (0, import_libs10.getRealColor)("gray.g450"),
            ...this.inputProps,
            style: [this.props.style, styles12.inputStyle],
            ref: this.inputRef
          }
        )
      }
    );
  }
};
var RichTextInput_default = RichTextInput;
var styles12 = import_react_native15.StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    height: 36
  },
  inputStyle: {
    flex: 1,
    height: 36
  },
  solidBorder: {
    borderWidth: 1,
    borderColor: import_libs10.COLOR.gray.g200,
    borderRadius: 5
  }
});

// src/TextInput/index.tsx
var import_react4 = __toESM(require("react"));
var import_react_native16 = require("react-native");
var import_libs11 = require("@rocket-mono/libs");

// ../../node_modules/@fortawesome/free-solid-svg-icons/index.mjs
var faCircleExclamation = {
  prefix: "fas",
  iconName: "circle-exclamation",
  icon: [512, 512, ["exclamation-circle"], "f06a", "M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"]
};
var faCheck = {
  prefix: "fas",
  iconName: "check",
  icon: [448, 512, [10003, 10004], "f00c", "M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"]
};

// src/TextInput/index.tsx
var import_atoms2 = require("@rocket/atoms");
var import_jsx_runtime17 = require("react/jsx-runtime");
var TextInput = ({
  maxByteLength = 0,
  borderType,
  borderColor,
  containerStyle,
  inputStyle,
  errorMessage,
  placeholderTextColor,
  onChangeText,
  onFocus,
  onBlur,
  ...inputProps
}) => {
  const [isFocus, setIsFocus] = import_react4.default.useState(false);
  const handleChangeText = (text) => {
    if (onChangeText)
      onChangeText(maxByteLength > 0 ? (0, import_libs11.cutByteString)(text, maxByteLength) : text);
  };
  const handleFocus = (0, import_react4.useCallback)(
    (e) => {
      onFocus && onFocus(e);
      setIsFocus(true);
    },
    [onFocus]
  );
  const handleBlur = (0, import_react4.useCallback)(
    (e) => {
      onBlur && onBlur(e);
      setIsFocus(false);
    },
    [onFocus]
  );
  return /* @__PURE__ */ (0, import_jsx_runtime17.jsxs)(View_default, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime17.jsx)(
      View_default,
      {
        style: [
          styles13.container,
          borderType === "solid" ? styles13.solidBorder : borderType === "underline" ? styles13.underlineBorder : void 0,
          borderColor && { borderColor: (0, import_libs11.getRealColor)(borderColor) },
          containerStyle,
          !!errorMessage && { borderColor: import_libs11.COLOR.main.red }
        ],
        children: /* @__PURE__ */ (0, import_jsx_runtime17.jsx)(
          import_react_native16.TextInput,
          {
            ...inputProps,
            placeholderTextColor: placeholderTextColor ?? import_libs11.COLOR.gray.g450,
            onFocus: handleFocus,
            onBlur: handleBlur,
            style: [
              styles13.inputStyle,
              inputProps.style,
              inputStyle,
              isFocus && { borderWidth: 2, borderColor: import_libs11.COLOR.primary.blue400 }
            ],
            onChangeText: handleChangeText
          }
        )
      }
    ),
    maxByteLength > 0 && /* @__PURE__ */ (0, import_jsx_runtime17.jsx)(View_default, { style: [styles13.alignFlexEnd], children: /* @__PURE__ */ (0, import_jsx_runtime17.jsx)(Text_default, { fontName: "subTextRegular", fontColor: "mono.paleBlack", children: `( ${(0, import_libs11.getByteLength)(
      inputProps.value || ""
    )} / ${maxByteLength} )` }) }),
    errorMessage && /* @__PURE__ */ (0, import_jsx_runtime17.jsxs)(View_default, { style: styles13.errorArea, children: [
      /* @__PURE__ */ (0, import_jsx_runtime17.jsx)(
        View_default,
        {
          style: {
            ...import_react_native16.Platform.select({
              web: {
                marginTop: 2
              }
            })
          },
          children: /* @__PURE__ */ (0, import_jsx_runtime17.jsx)(import_atoms2.FAIcon, { iconName: faCircleExclamation, size: "xs", color: "status.busy" })
        }
      ),
      /* @__PURE__ */ (0, import_jsx_runtime17.jsx)(Text_default, { style: styles13.errorMessage, children: errorMessage })
    ] })
  ] });
};
var TextInput_default = TextInput;
var styles13 = import_react_native16.StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    height: 36,
    backgroundColor: import_libs11.COLOR.mono.white
  },
  inputStyle: {
    flex: 1,
    height: 36,
    paddingHorizontal: 12
  },
  alignFlexEnd: { alignItems: "flex-end" },
  solidBorder: {
    borderWidth: 1,
    borderColor: import_libs11.COLOR.gray.g200,
    borderRadius: 5
  },
  underlineBorder: {
    borderBottomWidth: 1,
    borderColor: import_libs11.COLOR.gray.g200
  },
  errorArea: {
    flexDirection: "row",
    marginTop: 4
  },
  errorMessage: {
    ...import_libs11.FONT.txtXsMedium,
    color: import_libs11.COLOR.status.busy,
    marginLeft: 2
  }
});

// src/Select/index.tsx
var import_react_native19 = require("react-native");
var import_libs14 = require("@rocket-mono/libs");

// src/Select/ItemGroup.tsx
var import_react_native18 = require("react-native");
var import_libs13 = require("@rocket-mono/libs");

// src/Select/Item/index.tsx
var React10 = __toESM(require("react"));
var import_react_native17 = require("react-native");
var import_libs12 = require("@rocket-mono/libs");
var import_atoms3 = require("@rocket/atoms");
var import_jsx_runtime18 = require("react/jsx-runtime");
var SelectItem = ({ item, mode }) => {
  const [isHover, setIsHover] = React10.useState(false);
  return /* @__PURE__ */ (0, import_jsx_runtime18.jsxs)(
    import_react_native17.Pressable,
    {
      onPress: item.onPress,
      onHoverIn: () => setIsHover(true),
      onHoverOut: () => setIsHover(false),
      style: [
        styles14.container,
        mode === "LIGHT" && styles14.lightContainer,
        isHover && { backgroundColor: import_libs12.COLOR.darkmode.warm400 },
        mode === "LIGHT" && isHover && { backgroundColor: import_libs12.COLOR.gray.g050 },
        item.type === "INFO" && { backgroundColor: import_libs12.COLOR.gray.g050 }
      ],
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime18.jsxs)(import_react_native17.View, { style: {
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center"
        }, children: [
          /* @__PURE__ */ (0, import_jsx_runtime18.jsxs)(import_react_native17.View, { style: { flexDirection: "row", alignItems: "center", flex: 1 }, children: [
            item.beforeIconName && /* @__PURE__ */ (0, import_jsx_runtime18.jsx)(
              import_atoms3.FAIcon,
              {
                iconName: item.beforeIconName,
                size: item.beforeIconSize ? item.beforeIconSize : "xs",
                color: item.beforeIconColor ? item.beforeIconColor : "darkmode.cool300"
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime18.jsx)(import_react_native17.Text, { style: [
              styles14.labelText,
              { fontSize: item.labelSize },
              isHover && { color: import_libs12.COLOR.mono.white },
              mode === "LIGHT" && { color: import_libs12.COLOR.gray.g800 },
              item.beforeIconName && { marginLeft: 4 },
              item.selected && { color: import_libs12.COLOR.primary.blue500 }
            ], children: item.label })
          ] }),
          /* @__PURE__ */ (0, import_jsx_runtime18.jsxs)(import_react_native17.View, { children: [
            item.type === "COMMON" && item.afterIconName && /* @__PURE__ */ (0, import_jsx_runtime18.jsx)(
              import_atoms3.FAIcon,
              {
                iconName: item.afterIconName,
                size: item.afterIconSize ? item.afterIconSize : "xs",
                color: item.selected ? "primary.blue500" : "transparent.base"
              }
            ),
            item.type === "SWITCH" && /* @__PURE__ */ (0, import_jsx_runtime18.jsx)(import_jsx_runtime18.Fragment, {})
          ] })
        ] }),
        item.aboutLabel && /* @__PURE__ */ (0, import_jsx_runtime18.jsx)(import_react_native17.Text, { style: styles14.aboutlabelText, children: item.aboutLabel })
      ]
    }
  );
};
var Item_default = SelectItem;
var styles14 = import_react_native17.StyleSheet.create({
  container: {
    paddingVertical: 8,
    paddingHorizontal: 12,
    backgroundColor: import_libs12.COLOR.darkmode.warm600
  },
  lightContainer: {
    backgroundColor: import_libs12.COLOR.mono.white
  },
  labelText: {
    ...import_libs12.FONT.txtSm,
    color: import_libs12.COLOR.darkmode.cool300
  },
  aboutlabelText: {
    ...import_libs12.FONT.txt2Xs,
    color: import_libs12.COLOR.gray.g450,
    marginTop: 4
  }
});

// src/Select/ItemGroup.tsx
var import_jsx_runtime19 = require("react/jsx-runtime");
var SelectItemGroup = ({ list, mode }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime19.jsxs)(import_react_native18.View, { style: styles15.itemGroup, children: [
    list.beforeDiv && /* @__PURE__ */ (0, import_jsx_runtime19.jsx)(import_react_native18.View, { style: [styles15.divLine, mode === "LIGHT" && styles15.divLightLine] }),
    list.group.map((item, idx) => /* @__PURE__ */ (0, import_jsx_runtime19.jsx)(Item_default, { mode, item }, `selectItem-${idx}`)),
    list.afterDiv && /* @__PURE__ */ (0, import_jsx_runtime19.jsx)(import_react_native18.View, { style: [styles15.divLine, mode === "LIGHT" && styles15.divLightLine] })
  ] });
};
var ItemGroup_default = SelectItemGroup;
var styles15 = import_react_native18.StyleSheet.create({
  itemGroup: {
    position: "relative"
  },
  divLine: {
    width: "100%",
    height: 1,
    backgroundColor: import_libs13.COLOR.darkmode.cool600
  },
  divLightLine: {
    backgroundColor: import_libs13.COLOR.gray.g150
  }
});

// src/Select/index.tsx
var import_jsx_runtime20 = require("react/jsx-runtime");
var Select = ({ list, visible, setVisible, mode, width }) => {
  return visible && /* @__PURE__ */ (0, import_jsx_runtime20.jsx)(
    ClickOutside_default,
    {
      onClickOutside: () => {
        setVisible && setVisible(false);
      },
      children: /* @__PURE__ */ (0, import_jsx_runtime20.jsx)(
        import_react_native19.View,
        {
          style: [styles16.container, mode === "LIGHT" && styles16.lightContainer, { width }],
          children: list.map((item, idx) => /* @__PURE__ */ (0, import_jsx_runtime20.jsx)(ItemGroup_default, { mode, list: item }, `selectItemGroup-${idx}`))
        }
      )
    }
  );
};
var Select_default = Select;
var styles16 = import_react_native19.StyleSheet.create({
  container: {
    overflow: "hidden",
    width: 156,
    backgroundColor: import_libs14.COLOR.darkmode.warm600,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: import_libs14.COLOR.darkmode.cool600,
    ...(0, import_libs14.getBoxShadow)(0, 0, 0, 0.1, 4, 0, 0, 0)
  },
  lightContainer: {
    backgroundColor: import_libs14.COLOR.mono.white,
    borderColor: import_libs14.COLOR.gray.g200
  }
});

// src/IconButton/index.tsx
var React11 = __toESM(require("react"));
var import_react_native20 = require("react-native");
var import_libs15 = require("@rocket-mono/libs");
var import_atoms4 = require("@rocket/atoms");
var import_jsx_runtime21 = require("react/jsx-runtime");
var IconButton = ({ type, darkmode, size, iconName, iconColor, onPress }) => {
  const [isHover, setIsHover] = React11.useState(false);
  const [sizePack, setSizePack] = React11.useState({ iconSize: 10, buttonSize: 16 });
  React11.useEffect(() => {
    SizeOutput();
  }, [size]);
  const SizeOutput = () => {
    return size === "xsmall" ? setSizePack({ iconSize: 6, buttonSize: 12 }) : size === "small" ? setSizePack({ iconSize: 8, buttonSize: 16 }) : size === "medium" ? setSizePack({ iconSize: 10, buttonSize: 20 }) : size === "large" ? setSizePack({ iconSize: 12, buttonSize: 24 }) : size === "xlarge" ? setSizePack({ iconSize: 16, buttonSize: 32 }) : 16;
  };
  return /* @__PURE__ */ (0, import_jsx_runtime21.jsx)(
    import_react_native20.Pressable,
    {
      onPress: onPress && onPress,
      onHoverIn: () => setIsHover(true),
      onHoverOut: () => setIsHover(false),
      style: [
        styles17.container,
        { width: sizePack.buttonSize, height: sizePack.buttonSize },
        type === "CIRCLE" && styles17.roundSquare,
        type === "CIRCLELINE" && styles17.roundSquare,
        type === "ROUNDSQUARE" && styles17.roundSquare,
        type === "ROUNDSQUARE" && darkmode && styles17.roundSquareDarkmode,
        darkmode && isHover && { backgroundColor: import_libs15.COLOR.gray.g800 }
      ],
      children: /* @__PURE__ */ (0, import_jsx_runtime21.jsx)(import_react_native20.View, { style: [styles17.container, { width: sizePack.iconSize, height: sizePack.iconSize }], children: /* @__PURE__ */ (0, import_jsx_runtime21.jsx)(import_atoms4.FAIcon, { iconName, color: iconColor ? iconColor : "gray.g450" }) })
    }
  );
};
var IconButton_default = IconButton;
var styles17 = import_react_native20.StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center"
  },
  roundSquare: {
    borderRadius: 8
  },
  roundSquareDarkmode: {
    backgroundColor: import_libs15.COLOR.gray.g900
  }
});

// src/SkeletonParts/index.tsx
var React12 = __toESM(require("react"));
var import_react_native21 = require("react-native");
var import_libs16 = require("@rocket-mono/libs");
var import_jsx_runtime22 = require("react/jsx-runtime");
var SkeletonCircle = ({ width = 40, height = 40, color }) => {
  const [opacityAnim] = React12.useState(new import_react_native21.Animated.Value(1));
  const [animatedLoopCheck, setAnimatedLoopCheck] = React12.useState(1);
  React12.useEffect(() => {
    if (animatedLoopCheck === 0) {
      import_react_native21.Animated.timing(opacityAnim, {
        toValue: 1,
        duration: 2e3,
        useNativeDriver: false
      }).start();
      setTimeout(() => {
        setAnimatedLoopCheck(1);
      }, 2500);
    } else {
      import_react_native21.Animated.timing(opacityAnim, {
        toValue: 0.5,
        duration: 2e3,
        useNativeDriver: false
      }).start();
      setTimeout(() => {
        setAnimatedLoopCheck(0);
      }, 2500);
    }
  }, [animatedLoopCheck]);
  return /* @__PURE__ */ (0, import_jsx_runtime22.jsx)(
    import_react_native21.Animated.View,
    {
      style: [
        styles18.skeletonCircle,
        { width, height, opacity: opacityAnim },
        color && { backgroundColor: (0, import_libs16.getRealColor)(color) }
      ]
    }
  );
};
var SkeletonBar = ({ width = 40, height = 40, color }) => {
  const [opacityAnim] = React12.useState(new import_react_native21.Animated.Value(1));
  const [animatedLoopCheck, setAnimatedLoopCheck] = React12.useState(1);
  React12.useEffect(() => {
    if (animatedLoopCheck === 0) {
      import_react_native21.Animated.timing(opacityAnim, {
        toValue: 1,
        duration: 2e3,
        useNativeDriver: false
      }).start();
      setTimeout(() => {
        setAnimatedLoopCheck(1);
      }, 2500);
    } else {
      import_react_native21.Animated.timing(opacityAnim, {
        toValue: 0.5,
        duration: 2e3,
        useNativeDriver: false
      }).start();
      setTimeout(() => {
        setAnimatedLoopCheck(0);
      }, 2500);
    }
  }, [animatedLoopCheck]);
  return /* @__PURE__ */ (0, import_jsx_runtime22.jsx)(
    import_react_native21.Animated.View,
    {
      style: [
        styles18.skeletonBox,
        { width, height, opacity: opacityAnim },
        color && { backgroundColor: (0, import_libs16.getRealColor)(color) }
      ]
    }
  );
};
var SkeletonSquare = ({ width = 40, height = 40, color }) => {
  const [opacityAnim] = React12.useState(new import_react_native21.Animated.Value(1));
  const [animatedLoopCheck, setAnimatedLoopCheck] = React12.useState(1);
  React12.useEffect(() => {
    if (animatedLoopCheck === 0) {
      import_react_native21.Animated.timing(opacityAnim, {
        toValue: 1,
        duration: 2e3,
        useNativeDriver: false
      }).start();
      setTimeout(() => {
        setAnimatedLoopCheck(1);
      }, 2500);
    } else {
      import_react_native21.Animated.timing(opacityAnim, {
        toValue: 0.5,
        duration: 2e3,
        useNativeDriver: false
      }).start();
      setTimeout(() => {
        setAnimatedLoopCheck(0);
      }, 2500);
    }
  }, [animatedLoopCheck]);
  return /* @__PURE__ */ (0, import_jsx_runtime22.jsx)(
    import_react_native21.Animated.View,
    {
      style: [
        styles18.skeletonSquare,
        { width, height, opacity: opacityAnim },
        color && { backgroundColor: (0, import_libs16.getRealColor)(color) }
      ]
    }
  );
};
var styles18 = import_react_native21.StyleSheet.create({
  skeletonCircle: {
    borderRadius: 30,
    backgroundColor: import_libs16.COLOR.gray.g150
  },
  skeletonSquare: {
    borderRadius: 5,
    backgroundColor: import_libs16.COLOR.gray.g200
  },
  skeletonBox: {
    borderRadius: 2,
    backgroundColor: import_libs16.COLOR.gray.g100
  }
});

// src/ButtonWideFooter/index.tsx
var import_react_native22 = require("react-native");
var import_libs17 = require("@rocket-mono/libs");
var import_jsx_runtime23 = require("react/jsx-runtime");
var ButtonWideFooter = ({ buttonText, subButton, disabled, onPress }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime23.jsx)(
    import_react_native22.Pressable,
    {
      onPress: () => !disabled && onPress?.(),
      style: [styles19.container, disabled && styles19.disabledContainer, subButton && styles19.subButtonContainer],
      children: /* @__PURE__ */ (0, import_jsx_runtime23.jsx)(Text_default, { style: [styles19.textStyle, disabled && styles19.textDisabledStyle, subButton && styles19.subButtonStyle], children: buttonText })
    }
  );
};
var ButtonWideFooter_default = ButtonWideFooter;
var styles19 = import_react_native22.StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: 48,
    borderRadius: 8,
    backgroundColor: import_libs17.COLOR.primary.blue500
  },
  disabledContainer: {
    backgroundColor: import_libs17.COLOR.gray.g150,
    cursor: "auto"
  },
  subButtonContainer: {
    backgroundColor: import_libs17.COLOR.mono.white,
    borderWidth: 1,
    borderColor: import_libs17.COLOR.gray.g300
  },
  textStyle: {
    ...import_libs17.FONT.txtMdBold,
    color: import_libs17.COLOR.mono.white
  },
  textDisabledStyle: {
    color: import_libs17.COLOR.gray.g400
  },
  subButtonStyle: {
    ...import_libs17.FONT.txtMd,
    color: import_libs17.COLOR.gray.g700
  }
});

// src/Checkbox/index.tsx
var import_react_native23 = require("react-native");
var import_libs18 = require("@rocket-mono/libs");
var import_atoms5 = require("@rocket/atoms");
var import_jsx_runtime24 = require("react/jsx-runtime");
var Checkbox = ({ checked = false, disabled = false, type }) => {
  if (disabled) {
    return /* @__PURE__ */ (0, import_jsx_runtime24.jsx)(import_react_native23.View, { style: styles20.base });
  }
  if (checked) {
    return /* @__PURE__ */ (0, import_jsx_runtime24.jsx)(
      import_react_native23.View,
      {
        style: [
          styles20.base,
          { borderWidth: 0, backgroundColor: import_libs18.COLOR.primary.blue500 },
          type === "TODO" && { backgroundColor: import_libs18.COLOR.main.turquoise },
          type === "SCHEDULE" && { backgroundColor: import_libs18.COLOR.main.yellow },
          type === "GATHERING" && { backgroundColor: import_libs18.COLOR.sub.purple }
        ],
        children: /* @__PURE__ */ (0, import_jsx_runtime24.jsx)(import_atoms5.FAIcon, { iconName: faCheck, size: "xs", color: "mono.white" })
      }
    );
  }
  return /* @__PURE__ */ (0, import_jsx_runtime24.jsx)(import_react_native23.View, { style: styles20.base });
};
var Checkbox_default = Checkbox;
var styles20 = import_react_native23.StyleSheet.create({
  base: {
    justifyContent: "center",
    alignItems: "center",
    width: 18,
    height: 18,
    borderWidth: 1,
    borderColor: import_libs18.COLOR.gray.g300,
    borderRadius: 4,
    backgroundColor: import_libs18.COLOR.mono.white
  }
});
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  Badge,
  Button,
  ButtonWideFooter,
  Checkbox,
  ClickOutside,
  DialogBottomView,
  DialogView,
  DivLine,
  IconButton,
  Image,
  Modal,
  RichTextInput,
  Select,
  SkeletonBar,
  SkeletonCircle,
  SkeletonSquare,
  Text,
  TextInput,
  View
});
