import * as React from 'react'

import { removeItemAtIndex, replaceItemAtIndex } from '@rocket-mono/libs'

import Context from './context'
import type {
  AssigneeIdType,
  CardCollectionPieceGroupType,
  CardCollectionType,
  ProviderProps,
  SaveSchedulePayload,
  SaveTodoPayload,
  WorkCardGathering,
  WorkCardType,
} from './types'
import type {
  Card,
  CardCollection,
  CardShareType,
  SaveCardCollectionPayload,
  SaveCardCollectionPieceGroupPayload,
  SaveCardCollectionPiecePayload,
  SaveCardCollectionRegacyPayload,
} from '@rocket-mono/types'
import { useAstro } from '../../AstroProvider'
import { useWorkChannel } from '../ChannelProvider'
import { useSubscription } from '../../StompProvider'
import storage from '../../storage'
import { useToast } from '@rocket/components'
import { useTranslation } from 'react-i18next'

export const WorkCardProvider = ({ cardId, children, onUnauthorized, onDelete }: ProviderProps) => {
  const { t } = useTranslation()
  const { astro } = useAstro()
  const { showToastMessage } = useToast()

  const { channelId, currentChannelMembers } = useWorkChannel()

  const [currentCard, setCurrentCard] = React.useState<WorkCardType | null>()
  const [workCardList, setWorkCardList] = React.useState<WorkCardGathering[]>()

  const updateCardList = React.useCallback(
    (card: Card) => {
      // console.log('updateCardList', currentCard, card.id, cardId, String(currentCard?.no) === cardId)
      if (card.id === cardId) {
        fetchCard(cardId)
      }
      setWorkCardList((prev) => {
        if (prev === undefined) return [card]
        const idx = prev.findIndex(({ id }) => id === card.id)
        return idx < 0 ? [...prev, card] : replaceItemAtIndex(prev, idx, card)
      })
    },
    [cardId],
  )

  const deleteCardList = React.useCallback((cardId: string) => {
    setWorkCardList((prev) => {
      if (prev === undefined) return prev
      const idx = prev.findIndex(({ id }) => id === cardId)
      return idx < 0 ? prev : removeItemAtIndex(prev, idx)
    })
  }, [])

  const saveTodo = React.useCallback(
    (cardPayload: SaveTodoPayload) =>
      Promise.resolve(
        cardPayload.id !== undefined ? astro.updateCardTodo(cardPayload) : astro.createCardTodo(cardPayload),
      ).then((card) => {
        updateCardList(card)
        return card.id
      }),
    [],
  )
  const readTodo = React.useCallback(
    (cardId: string) => Promise.resolve(workCardList?.find(({ id }) => id === cardId) || null),
    [workCardList],
  )
  const saveSchedule = React.useCallback(
    (cardPayload: SaveSchedulePayload) =>
      Promise.resolve(
        cardPayload.id !== undefined ? astro.updateCardMetting(cardPayload) : astro.createCardMetting(cardPayload),
      ).then((card) => {
        updateCardList(card)
        return card.id
      }),
    [],
  )
  const readSchedule = React.useCallback(
    (cardId: string) => Promise.resolve(workCardList?.find(({ id }) => id === cardId) || null),
    [],
  )

  const saveGatheringCard = React.useCallback(
    (cardPayload: SaveCardCollectionRegacyPayload): Promise<WorkCardGathering> => {
      return astro.createCardCollectionLegacy(cardPayload).then((card) => {
        updateCardList(card)
        return card
      })
    },
    [currentChannelMembers],
  )

  const saveGathering = React.useCallback(
    (
      cardId: string,
      gatheringPayload: Omit<SaveCardCollectionPayload, 'cardId'>,
      assigneeIds: AssigneeIdType[],
    ): Promise<CardCollectionType> => {
      return new Promise<CardCollection>((resolve) => {
        if (gatheringPayload.id) astro.updateCardCollection({ ...gatheringPayload, cardId }).then(resolve)
        else astro.createCardCollection({ ...gatheringPayload, cardId }).then(resolve)
      }).then((o) => {
        return Promise.all([
          astro.deleteAssignees(assigneeIds.map(({ id }) => ({ id: id ?? '' })).filter(({ id }) => id !== '')),
          ...assigneeIds.map(({ userId, userEmail }) => {
            return astro.createAssignee({
              relatedDomain: 'card.collection',
              relatedDomainId: o.id,
              userId: Number(userId),
              userEmail,
            })
          }),
        ]).then((res) => {
          console.log('createAssignee', res)
          return o
        })
      })
    },
    [currentChannelMembers],
  )

  const readGatheringPieces = React.useCallback((collectionId: string): Promise<CardCollectionPieceGroupType[]> => {
    return astro.readCardCollectionPieceListGroup(collectionId).then((groupList) => {
      return Promise.all(
        groupList.map((group) => {
          return astro
            .readCardCollectionPieceList(collectionId, group.id)
            .then((list) =>
              Promise.all(
                list.map((o) => {
                  return o.userId
                    ? astro
                        .readUser(String(o.userId))
                        .then(({ userName, userEmail }) => ({ ...o, userName, userEmail }))
                    : o
                }),
              ),
            )
            .then((pieces) => {
              console.log('pieces', pieces)

              return Promise.all(
                pieces.map((piece) => {
                  if (piece.type === 'FILE' && piece.content) {
                    return astro
                      .readChannelFile('card_collection_piece', piece.content)
                      .then((file) => ({ ...piece, file }))
                  }
                  return Promise.resolve(piece)
                }),
              )
            })
            .then((list) => ({ ...group, list }))
        }),
      )
        .then((pieces) => {
          console.log('readGatheringPieces', groupList, pieces)
          return pieces
        })
        .catch(() => [])
    })
  }, [])

  const readGathering = React.useCallback(
    async (cardId: string): Promise<WorkCardGathering | null> => {
      let card = workCardList?.find(({ id }) => id === cardId)
      if (card === undefined) return Promise.reject(null)
      const gatherings = await astro
        .readCardCollectionList(cardId)
        .then((gatherings) => {
          return Promise.all(gatherings.map((o) => readGatheringPieces(o.id).then((pieces) => ({ ...o, pieces }))))
        })
        .then((gatherings) => {
          return Promise.all(
            gatherings.map((o) =>
              astro
                .readAssignee('card.collection', o.id)
                .then((list) =>
                  Promise.all(
                    list.map((o) => {
                      return o.userId
                        ? astro.readUser(String(o.userId)).then((user) => ({ ...o, userName: user.userName }))
                        : o
                    }),
                  ),
                )
                .then((assigneeList) => ({ ...o, assigneeList })),
            ),
          )
        })
        .catch(() => [])
      return Promise.resolve({ ...card, gatherings })
    },
    [workCardList],
  )

  const saveGatheringPieceGroup = React.useCallback(
    (payload: SaveCardCollectionPieceGroupPayload) => {
      if (payload.id === undefined)
        return astro.createCardCollectionPieceGroup(payload).then((piece) => {
          if (cardId) fetchCard(cardId)
          return piece
        })
      else
        return astro.updateCardCollectionPieceGroup(payload).then((piece) => {
          if (cardId) fetchCard(cardId)
          return piece
        })
    },
    [cardId],
  )

  const saveGatheringPiece = React.useCallback(
    (payload: SaveCardCollectionPiecePayload) => {
      if (payload.id === undefined) {
        const { collectionId, userId, type, groupId } = payload

        if (groupId) {
          return astro.createCardCollectionPiece({ ...payload, groupId }).then((piece) => {
            if (cardId) fetchCard(cardId)
            return piece
          })
        } else
          return astro.createCardCollectionPieceGroup({ collectionId, userId, type, isDone: true }).then((group) => {
            return astro.createCardCollectionPiece({ ...payload, groupId: group.id }).then((piece) => {
              if (cardId) fetchCard(cardId)
              return piece
            })
          })
      } else
        return astro.updateCardCollectionPiece(payload).then((piece) => {
          if (cardId) fetchCard(cardId)
          return piece
        })
    },
    [cardId],
  )

  const deleteGatheringPiece = React.useCallback(
    (collectionId: string, id: string) => {
      astro.deleteCardCollectionPiece(collectionId, id).then(() => {
        if (cardId) fetchCard(cardId)
      })
    },
    [cardId],
  )

  const cacheCard = React.useCallback((data) => {
    storage.save({ key: 'card-cache', data }).catch((res) => console.error('cacheCard', res))
  }, [])

  const readCacheCard = React.useCallback(() => {
    return storage.load({ key: 'card-cache' }).catch(() => null)
  }, [])
  const clearCacheCard = React.useCallback(() => {
    return storage.remove({ key: 'card-cache' })
  }, [])

  const fetchCard = React.useCallback(
    (cardId: string) => {
      astro
        .readCard(cardId, channelId)
        .then((legacyCard) => {
          console.log('fetchCard- legacyCard', legacyCard)
          if (legacyCard.type === 'COLLECTION') {
            return astro
              .readCardCollectionList(cardId)
              .then((gatherings) =>
                Promise.all(
                  gatherings.map((o) =>
                    readGatheringPieces(o.id).then((pieces) => ({
                      ...o,
                      pieces,
                    })),
                  ),
                ),
              )
              .then((gatherings) => {
                return Promise.all(
                  gatherings.map((o) =>
                    astro
                      .readAssignee('card.collection', o.id)
                      .then((list) =>
                        Promise.all(
                          list.map((o) => {
                            return o.userId
                              ? astro.readUser(String(o.userId)).then((user) => ({ ...o, userName: user.userName }))
                              : o
                          }),
                        ),
                      )
                      .then((assigneeList) => ({ ...o, assigneeList })),
                  ),
                )
              })
              .then((gatherings) => ({ ...legacyCard, gatherings }))
              .catch(() => ({ ...legacyCard, gatherings: [] }))
          } else {
            return Promise.resolve(legacyCard)
          }
        })
        .then((res) => {
          console.log('res', res)
          setCurrentCard(res)
        })
        .catch((err) => {
          console.error('WorkCardProvider:fetchCard', err)
          if (err.status === 403) {
            onUnauthorized()
          }
        })
    },
    [channelId],
  )

  const updateCard = React.useCallback(
    (cardId: string, payload: { isPublic?: boolean; cardShareType?: CardShareType }) => {
      return astro.updateCard(cardId, payload).then(updateCardList)
    },
    [updateCardList],
  )

  const deleteCard = React.useCallback((cardId: string) => {
    return astro.deleteCard(cardId).then(() => deleteCardList(cardId))
  }, [])

  React.useEffect(() => {
    astro
      .readCardList(channelId)
      .then(setWorkCardList)
      .catch(() => setWorkCardList([]))
  }, [channelId])

  React.useEffect(() => {
    if (cardId) {
      fetchCard(cardId)
    } else {
      setCurrentCard(null)
    }
  }, [channelId, cardId])

  console.log('CardProvider', channelId, cardId, currentCard)

  useSubscription(`/subscribe/card/${cardId}/update/`, () => {
    if (cardId) fetchCard(cardId)
  })

  useSubscription(`/subscribe/card/${cardId}/delete`, () => {
    if (cardId) {
      const duration = 1000
      showToastMessage({
        message: t('cardtodoedit.toast.delete'),
        viewPosition: 'TOP_RIGHT',
        duration,
      })
      setTimeout(() => onDelete(cardId), duration)
    }
  })

  if (currentCard === undefined) return null

  return (
    <Context.Provider
      value={{
        cardId,
        currentCard,
        workCardList,
        cacheCard,
        readCacheCard,
        clearCacheCard,
        updateCard,
        deleteCard,
        saveGatheringCard,
        saveGathering,
        readGathering,
        saveGatheringPiece,
        saveGatheringPieceGroup,
        deleteGatheringPiece,
        saveTodo,
        readTodo,
        saveSchedule,
        readSchedule,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export * from './hooks'
export type {
  AssigneeIdType,
  CardDateType,
  CardFormType,
  LocationType,
  CardGatheringFormType,
  WorkCardTodoState,
  WorkCardTodoParam,
} from './types'
