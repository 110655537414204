import React from 'react'

import { I18nextProvider } from 'react-i18next'
import { AstroProvider, SigningCampaignProvider, KakaotalkInAppProvider } from '@rocket-mono/providers'

import { i18nInstance } from '@rocket-mono/libs'

interface Props {
  children?: JSX.Element | JSX.Element[]
}

const RootProvider: React.FC<Props> = ({ children }) => {
  // FIXME: 실제로는 사용자 브라우저 언어를 기준으로 언어를 설정해야 함
  i18nInstance.changeLanguage('ko')
  return (
    <I18nextProvider i18n={i18nInstance}>
      <AstroProvider
        token={''}
        option={{
          environment: 'development',
          baseUrl: `https://rocket-v1-rocket-is-api-project-staging.staging.mycroft.kr`,
          authBaseUrl: 'https://rocket-v2-rocket-authentication-staging.staging.mycroft.kr',
          secureCdnUrl: `https://rocket-v1-rocket-is-api-app-staging.staging.mycroft.kr`,
          shortenerBaseUrl: 'https://rocket-v2-url-shortener-staging.staging.mycroft.kr',
          talkUrl: `https://rocket-v1-rocket-is-talk-react-staging.staging.mycroft.kr`,
          // talkUrl: 'http://localhost:3001',
          cardBaseUrl: `https://rocket-v1-rocket-is-api-talk-staging.staging.mycroft.kr`,
          talkBaseUrl: `https://rocket-v1-rocket-is-api-talk-staging.staging.mycroft.kr`,
          appApiLegacyUrl: `https://rocket-v1-rocket-is-api-app-staging.staging.mycroft.kr`,
          talkApiLegacyUrl: `https://rocket-v1-rocket-is-api-talk-staging.staging.mycroft.kr`,
          projectApiLegacyUrl: `https://rocket-v1-rocket-is-api-project-staging.staging.mycroft.kr`,
          workLegacyUrl: 'https://rocket-v1-rocket-is-biz-staging.staging.mycroft.kr',
          todoBaseUrl: 'https://rocket-v2-todo-staging.staging.mycroft.kr',
          fileBaseUrl: 'https://rocket-v2-file-staging.staging.mycroft.kr',
          assigneeBaseUrl: `https://rocket-v2-assignee-staging.staging.mycroft.kr`,
          userBaseUrl: `https://rocket-v1-rocket-is-api-app-staging.staging.mycroft.kr`,
          notificationBaseUrl: 'https://rocket-v2-rocket-notification-staging.staging.mycroft.kr',
          shopBaseUrl: `https://rocket-work-models-table-order-shop-staging.staging.gitops.mycroft.kr`,
          shopLayoutBaseUrl: `https://rocket-work-models-table-order-shop-layout-staging.staging.gitops.mycroft.kr`,
          menuBaseUrl: `https://rocket-work-models-table-order-menu-staging.staging.gitops.mycroft.kr`,
          menuCategoryBaseUrl: `https://rocket-work-models-table-order-menu-category-staging.staging.gitops.mycroft.kr`,
          menuCatalogBaseUrl: `https://rocket-work-models-table-order-menu-catalog-staging.staging.gitops.mycroft.kr`,
          menuLabelBaseUrl: `https://rocket-work-models-table-order-menu-label-staging.staging.gitops.mycroft.kr`,
          menuOptionBaseUrl: `https://rocket-work-models-table-order-menu-option-staging.staging.gitops.mycroft.kr`,
          orderBaseUrl: `https://rocket-work-models-table-order-order-staging.staging.gitops.mycroft.kr`,
          tableBaseUrl: `https://rocket-work-models-table-order-table-staging.staging.gitops.mycroft.kr`,
          basketBaseUrl: `https://rocket-work-models-table-order-basket-staging.staging.gitops.mycroft.kr`,
          tableorderFileBaseUrl: `https://rocket-work-models-table-order-file-staging.staging.gitops.mycroft.kr`,
          bookmarkBaseUrl: 'https://rocket-v2-rocket-bookmark-staging.staging.mycroft.kr',
          campaignBaseUrl: 'https://rocket-v2-services-campaign-staging.staging.mycroft.kr',
          formBaseUrl: 'https://rocket-v2-services-form-staging.staging.mycroft.kr',
        }}
      >
        <SigningCampaignProvider>
          <KakaotalkInAppProvider>
            <>{children}</>
          </KakaotalkInAppProvider>
        </SigningCampaignProvider>
      </AstroProvider>
    </I18nextProvider>
  )
}

export default RootProvider
