'use client'
"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.ts
var src_exports = {};
__export(src_exports, {
  default: () => src_default
});
module.exports = __toCommonJS(src_exports);
var AstroNav;
((_AstroNav) => {
  const _AstroNav2 = class {
    constructor(option) {
      this.option = option;
    }
    /**
     * 여러 함수를 실행하기 전에 지정돼야 할 컨텍스트를 설정합니다.
     * @param context AstroNavContext 객체 정의 참고
     */
    prepare(context) {
      this.context = context;
    }
    // get route$(): Observable<RouteCommand> {
    //   return this.routeSubject$
    // }
    // /**
    //  * 미리 정의된 라우트로 이동합니다. 라우트로 이동하면 다음 이벤트를 발생시킵니다.
    //  * routeChangeStart, routeChangeComplete
    //  * @param route 이동할 라우팅 이름
    //  * @param payload 라우트에 전달될 페이로드
    //  */
    // go(route: string, payload: RouteOption | undefined) {
    //   this.routeSubject$.next({ route: { name: route }, payload })
    // }
    /**
     * 프론트엔드 프로그램 간 message 콜백/핸들러를 제공하기 위한 메소드입니다.
     * 미리 정의된 액션에 대한 이벤트를 등록합니다.
     * @param action 액션명 ex) AstroNav.Action.openModalMemberInfo
     * @param callback 이벤트가 발생할 때 실행할 콜백함수
     */
    on(action, callback) {
      if (!window) {
        throw Error("window is undefined");
      }
      if (_AstroNav2.messageListeners[action]) {
        return;
      }
      _AstroNav2.messageListeners[action] = ({ data }) => {
        if ((data == null ? void 0 : data.action) === action) {
          callback({ payload: data.payload });
        }
      };
      window.addEventListener("message", _AstroNav2.messageListeners[action]);
    }
    /**
     * 프론트엔드 프로그램 간 message 콜백/핸들러를 제공하기 위한 메소드입니다.
     * 미리 정의된 액션에 대한 이벤트를 실행합니다.
     * @param action 액션명 ex) AstroNav.Action.openModalMemberInfo
     * @param payload 해당 이벤트 리스너에게 전달할 페이로드
     */
    emit(action, payload) {
      if (!window) {
        throw Error("window is undefined");
      }
      console.log("astroNav.emit", action, payload);
      window.postMessage(
        {
          action,
          payload
        },
        "*"
      );
    }
    /**
     * 프론트엔드 프로그램 간 message 콜백/핸들러를 제공하기 위한 메소드입니다.
     * 미리 정의된 액션에 대한 이벤트를 제거합니다.
     * @param action 액션명 ex) AstroNav.Action.openModalMemberInfo
     */
    clear(action) {
      if (!window) {
        throw Error("window is undefined");
      }
      if (!_AstroNav2.messageListeners[action])
        return;
      window.removeEventListener("message", _AstroNav2.messageListeners[action]);
    }
    // /**
    //  * 채팅방을 열기 위한 토큰을 교환하는 함수
    //  * @deprecated 구버전 구조에 맞춘 API로, 추후 퇴출될 예정입니다. (할당된 퇴출일정은 없음)
    //  * @param roomId 입장하려는 채팅방 id
    //  * @returns 채팅방 토큰
    //  */
    // private async exchangeChatroomToken(
    //   roomId: string,
    //   cardNo?: string,
    // ): Promise<string> {
    //   const uri = `${this.option.talkApiUrl}/api/talk/token`
    //   const formData = new FormData()
    //   formData.append('roomId', roomId)
    //   if (cardNo) {
    //     formData.append('cardNo', cardNo)
    //   }
    //   const method = 'POST'
    //   const headers = { 'X-AUTH-TOKEN': this.context!.token }
    //   const body = formData
    //   const roomToken = await fetch(uri, { method, headers, body })
    //     .then((res) => res.text())
    //     .catch((err) => console.error(err))
    //   if (!roomToken) throw new Error('chatroom token is empty')
    //   return roomToken
    // }
    // /**
    //  * 카드를 열기 위한 토큰을 교환하는 함수
    //  * @deprecated 구버전 구조에 맞춘 API로, 추후 퇴출될 예정입니다. (할당된 퇴출일정은 없음)
    //  * @param channelId 카드가 속한 채널 id
    //  * @param cardId 카드의 id
    //  * @returns 카드 토큰
    //  */
    // private async exchangeCardToken(
    //   channelId: string,
    //   cardId: string,
    // ): Promise<string> {
    //   const uri = `${this.option.talkApiUrl}/api/talk/token`
    //   const formData = new FormData()
    //   formData.append('roomId', channelId)
    //   formData.append('cardNo', cardId)
    //   const method = 'POST'
    //   const headers = { 'X-AUTH-TOKEN': this.context!.token }
    //   const body = formData
    //   const cardToken = await fetch(uri, { method, headers, body })
    //     .then((res) => res.text())
    //     .catch((err) => console.error(err))
    //   if (!cardToken) throw new Error('card token is empty')
    //   return cardToken
    // }
    // /**
    //  *
    //  * @param url 팝업창 주소
    //  * @param option 팝업창 옵션
    //  */
    // private async popup(url: string, option: PopupOption) {
    //   try {
    //     const win = window.open(
    //       '',
    //       `popupView-${option.uniqueId}`,
    //       `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=${option.w},height=${option.h},left=${option.x},top=${option.y}`,
    //     )
    //     if (win == null) {
    //       throw new Error('팝업 차단 기능이 설정되어 있습니다.')
    //     }
    //     if (win.location.href === 'about:blank') {
    //       win.location = url
    //       if (win !== null && typeof win !== 'undefined') {
    //         win.focus()
    //       }
    //     }
    //   } catch (e) {
    //     alert(
    //       '팝업 차단 기능이 설정되어있습니다\n\n차단 기능을 해제(팝업허용) 한 후 다시 이용해 주십시오.',
    //     )
    //   }
    // }
  };
  let AstroNav2 = _AstroNav2;
  AstroNav2.Action = Object.freeze({
    openCard: "openCard",
    openChat: "openChat",
    openDirectChat: "openDirectChat",
    openModalMemberInfo: "openModalMemberInfo",
    openModalInviteInfo: "openModalInviteInfo",
    openModalOwnershipInfo: "openModalOwnershipInfo",
    showToast: "showToast",
    markAsReadNoti: "markAsReadNoti",
    openConfirmCardAction: "openConfirmCardAction",
    openModalInvitation: "openModalInvitation",
    loading: "loading",
    onboarding: "onboarding",
    project: "project"
  });
  AstroNav2.messageListeners = {};
  _AstroNav.AstroNav = AstroNav2;
})(AstroNav || (AstroNav = {}));
var src_default = AstroNav.AstroNav;
