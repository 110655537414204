import * as React from 'react'

import { NativeModules, Platform } from 'react-native'
import Context from './context'
import type { ProviderProps } from './types'

const Provider = ({ children }: ProviderProps) => {
  const [platform, setPlatform] = React.useState('')
  const [language, setLanguage] = React.useState('')
  const [locale, setLocale] = React.useState('')

  React.useEffect(() => {
    setPlatform(Platform.OS)
    let $language = ''
    let $locale = ''
    if (Platform.OS === 'ios') {
      $locale =
        NativeModules.SettingsManager.settings.AppleLocale || NativeModules.SettingsManager.settings.AppleLanguages[0]
      $language = $locale.substring(0, 2)
    } else if (Platform.OS === 'android') {
      $locale = NativeModules.I18nManager.localeIdentifier
      $language = $locale.substring(0, 2)
    } else {
      if (navigator.language !== null) {
        $locale = navigator.language
      } else if ((navigator as any).userLanguage !== null) {
        $locale = (navigator as any).userLanguage
      } else if ((navigator as any).systemLanguage !== null) {
        $locale = (navigator as any).systemLanguage
      } else {
        $locale = 'korean'
      }
      $language = $locale.substring(0, 2)
    }
    setLanguage($language)
    setLocale($locale)
  }, [])

  if (platform === '' || language === '' || locale === '') {
    return <></>
  }

  return (
    <Context.Provider
      value={{
        platform,
        language,
        locale,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
