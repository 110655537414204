import * as React from 'react'

import type { User } from '@rocket-mono/types'
import Context from './context'
import type { ProviderProps } from './types'
import { useAstro } from '../AstroProvider'
import { useSubscription } from '../StompProvider'
const Provider = ({ children, onFailed }: ProviderProps) => {
  const { astro } = useAstro()

  const [currentUser, setCurrentUser] = React.useState<User>()

  const fetchCurrentUser = React.useCallback(() => {
    astro
      .readMe()
      .then(setCurrentUser)
      .catch((err) => {
        console.log('fetchCurrentUser', err)
        if ([403, 500].includes(err.status)) {
          onFailed()
        }
      })
  }, [])

  const updatePassword = React.useCallback(async (currentPassword: string, newPassword: string) => {
    await astro.changePassword(currentPassword, newPassword)
    fetchCurrentUser()
  }, [])
  const updateName = React.useCallback(async (name: string, lang: string) => {
    await astro.updateMe({
      userName: name,
      password: '',
      passwordConfirm: '',
      lang: lang,
    })
    fetchCurrentUser()
  }, [])
  const updateProfile = React.useCallback(async (file: File) => {
    const fileFormData = new FormData()
    fileFormData.append('file', file)
    await astro.updateProfile(fileFormData)
    fetchCurrentUser()
  }, [])
  const leaveUser = React.useCallback(async () => {
    await astro.leaveCurrentUser()
  }, [])

  React.useEffect(() => {
    fetchCurrentUser()
  }, [])

  useSubscription(`/subscribe/${currentUser?.id || ''}/state`, () => {
    fetchCurrentUser()
  })

  if (!currentUser) return <></>

  return (
    <Context.Provider
      value={{
        currentUser,
        updatePassword,
        updateName,
        updateProfile,
        leaveUser,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
