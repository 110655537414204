import * as React from 'react'
import { Platform, View } from 'react-native'

import { Text } from '@rocket-atoms/text'

import type { TextAvatarProps } from '../types'
import { getRandomColorSet } from '@rocket-mono/libs'

/**
 * TODO 새로운 폰트 스타일 추가시 fontSize 변경 필요
 */
export const TextAvatar: React.FC<TextAvatarProps> = ({ size, alt = '' }) => {
  const { color, backgroundColor } = getRandomColorSet('mono.white')

  // const fontSize = React.useMemo(() => {
  //   switch (size) {
  //     case 20:
  //       break;
  //     default:
  //       break;
  //   }
  // }, [size])

  return (
    <View
      style={{
        ...size,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor,
      }}
    >
      <Text
        fontName="textRegular"
        style={[
          {
            color,
            fontSize: size.width * 0.4,
            ...Platform.select({
              ios: { lineHeight: size.width },
              android: { lineHeight: size.width * 1.1 },
            }),
          },
        ]}
      >
        {getAltText(size.width, alt)}
      </Text>
    </View>
  )
}

const ENGRLISH_REGEXP = /^[a-z][a-z\d]*$/i

const getAltText = (size: number, alt = '') => {
  const text = alt.length <= 2 ? alt : alt.slice(0, 3)
  const isEng = ENGRLISH_REGEXP.test(text)
  return alt.slice(isEng ? 0 : 1, size === 20 ? 2 : 3)
}
