import * as React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'

import { RecoilRoot } from 'recoil'

import './index.css'

import router from './router'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <RecoilRoot>
    <RouterProvider router={router} />
  </RecoilRoot>,
)
