import * as RNSvg from 'react-native-svg'
import XEIcon from './XEIcon'
import FAIcon from './FAIcon'
import IconProfileLabelLeader from './IconProfileLabelLeader'
import IconStateCircle from './IconStateCircle'
import WorkIcon from './WorkIcon'
import LayoutTopLogo from './LayoutTopLogo'
import NoContentsIcon from './NoContentsIcon'
import NoProfileImage from './NoProfileImage'
import IconSns from './IconSns'
import SignTopLogo from './SignTopLogo'
import BoardLabel from './BoardLabel'

const Icon = Object.assign(XEIcon, {
  FA: FAIcon,
  XE: XEIcon,
})

export {
  RNSvg,
  Icon,
  XEIcon,
  FAIcon,
  IconProfileLabelLeader,
  NoProfileImage,
  IconStateCircle,
  WorkIcon,
  LayoutTopLogo,
  NoContentsIcon,
  IconSns,
  SignTopLogo,
  BoardLabel,
}
