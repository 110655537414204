import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect, Svg } from 'react-native-svg'

interface Props {
  size: number
  theme?: 'dark' | 'light'
}

const LayoutTopLogo: React.FC<Props> = ({ size, theme }) => {
  return (
    <Svg width={size} height={size / 1.941176} viewBox="0 0 33 17" fill="none">
      <G clipPath="url(#clip0_3693_439115)">
        <Path
          d="M3.34912 9.49968C3.04755 9.07057 3.08076 8.49361 3.38233 8.08151L6.98124 3.66345H3.19899C3.19899 3.66345 0.8874 6.45008 0.653584 6.74706C0.201893 7.34102 -0.0159813 8.06581 -3.92747e-05 8.8076C-3.92747e-05 9.64882 0.268318 10.456 0.803705 11.1154C1.05479 11.4281 3.14718 13.9675 3.14718 13.9675H6.99718L3.34779 9.50099L3.34912 9.49968Z"
          fill={theme === 'dark' ? '#ffffff' : '#1F2940'}
        />
        <Path
          d="M20.2038 11.0657C20.0537 10.8681 20.1029 10.5711 20.3048 10.4063H20.3221C20.9252 9.94446 21.4606 9.41722 21.9455 8.85728C22.6151 8.00036 22.9831 6.92888 22.9831 5.84039C22.9831 2.61025 20.5054 0.5 17.0247 0.5H12.3046C11.4676 0.5 10.8153 1.17638 10.8153 1.98359V15.5164C10.8153 16.3576 11.4849 17 12.3046 17H14.1963V3.46717H17.0088C18.5817 3.46717 19.5024 4.43922 19.5024 5.82469C19.5024 6.59919 19.1503 7.30828 18.5313 7.7701C17.6943 8.44648 17.1084 9.05614 16.9583 10.1276C16.8414 11.0015 16.9756 11.7263 17.4273 12.3857C17.7288 12.7978 20.8415 16.9843 20.8415 16.9843H24.6742C24.6915 17.0013 20.3393 11.2475 20.2052 11.067L20.2038 11.0657Z"
          fill={theme === 'dark' ? '#ffffff' : '#1F2940'}
        />
        <Path
          d="M28.9666 16.8783H33L30.5236 13.8457H26.473L28.9666 16.8783Z"
          fill={theme === 'dark' ? '#ffffff' : '#1F2940'}
        />
        <Path
          d="M32.2308 1.5126L32.9508 0.638672H28.9666L27.5943 2.33682L26.473 3.67126H30.4904L32.2308 1.5126Z"
          fill={theme === 'dark' ? '#ffffff' : '#1F2940'}
        />
      </G>
      <Defs>
        <ClipPath id="clip0_3693_439115">
          <Rect width={33} height={16.5} fill="white" transform="translate(0 0.5)" />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

export default LayoutTopLogo
