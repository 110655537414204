import React from 'react'

import { ErrorScreen } from '@rocket-mono/screen-workspace'

const LetterExpiredErrorPage: React.FC = () => {
  return (
    <>
      <ErrorScreen
        campaignTitle={''}
        formTitle={''}
        error={'이미 만료된 초대장입니다.'}
        onPressFeedback={function (): void {
          throw new Error('Function not implemented.')
        }}
        onPressClose={function (): void {
          throw new Error('Function not implemented.')
        }}
      />
    </>
  )
}

export default LetterExpiredErrorPage
