import React from 'react'
import { KakaoInAppOS } from './types'
import Context from './context'

interface Props {
  children?: JSX.Element | JSX.Element[]
}

export const KakaotalkInAppProvider = ({ children }: Props) => {
  const [userAgent, setUserAgent] = React.useState<string>('')
  const [mobileOS, setMobileOS] = React.useState<KakaoInAppOS>()

  const closeAppWindow = React.useCallback(() => {
    if (mobileOS === 'android') window.location.href = 'kakaotalk://inappbrowser/close'
    else if (mobileOS === 'ios') window.location.href = 'kakaoweb://closeBrowser'
  }, [mobileOS])

  const downloadFile = React.useCallback(
    (url: string) => {
      if (mobileOS === 'ios') {
        const link = document.createElement('a')
        link.href = url
        link.download = 'image.zip'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else {
        window.open(url, 'system')
      }
    },
    [mobileOS],
  )

  React.useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase()
    setUserAgent(userAgent)
  }, [])

  React.useEffect(() => {
    if (userAgent?.includes('android')) {
      setMobileOS('android')
    } else if (userAgent?.includes('iphone')) {
      setMobileOS('ios')
    }
  }, [userAgent])

  return <Context.Provider value={{ downloadFile, closeAppWindow, mobileOS, userAgent }}>{children}</Context.Provider>
}
export * from './hooks'

export default KakaotalkInAppProvider
