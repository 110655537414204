import { useContext } from 'react'

import type { ContextProps } from './types'

import Context from './context'

export const useKakaotalkInApp = () => {
  const context = useContext<ContextProps | undefined>(Context)

  if (context === undefined)
    throw new Error('There must be a KakaotalkInAppProvider as Ancestor of all Astro Hooks and HOCs')
  return context
}
