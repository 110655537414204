import React from 'react'

import Context from './context'
import { SignpadProviderProps } from './types'

export const SignpadProvider = ({ children }: SignpadProviderProps) => {
  const [signature, setSignature] = React.useState<string>('')

  const update = React.useCallback((signature: string) => {
    console.debug('[update] signature: ', { signature })
    setSignature(signature)
  }, [])

  const clear = React.useCallback(() => {
    setSignature('')
  }, [])

  const isEmpty = React.useMemo(() => {
    return !signature
  }, [signature])

  return (
    <Context.Provider
      value={{
        signature,
        update,
        clear,
        isEmpty,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export * from './hooks'
export * from './types'

export default SignpadProvider
