import * as React from 'react'

import '@toast-ui/editor/dist/toastui-editor-viewer.css'
import { Viewer as ToastViewer } from '@toast-ui/react-editor'

import { Props } from './types'

const Viewer: React.FC<Props> = ({ content }) => {
  return (
    <>
      <style>{`
        .toastui-editor-contents p {
          margin: 0;
        }
      `}</style>
      <ToastViewer usageStatistics={false} initialValue={content} />
    </>
  )
}

export default Viewer
