import * as React from 'react'

import { Pressable, Text, StyleSheet } from 'react-native'
import { COLOR, FONT } from '@rocket-mono/libs'


interface Props {
  type?: 'PRIMARY' | 'SECONDARY' | 'SECONDAYWHITE' | 'NEGATIVE' | 'BOTTOMBAR'
  size?: 'sm' | 'md' | 'lg'
  wide?: boolean
  disabled?: boolean
  text?: string
  onPress: ()=>void
}

const Button: React.FC<Props> = ({type='PRIMARY', size='md', wide, disabled, text, onPress} : Props) =>{
  return(
    <Pressable
      style={[
        styles.commonStyle,
        wide && {width:'100%'},
        type === 'SECONDARY' && styles.secondaryStyle,
        type === 'SECONDAYWHITE' && styles.secondaryWhiteStyle,
        type === 'SECONDAYWHITE' && styles.negativeStyle,
        type === 'SECONDAYWHITE' && styles.bottomBardStyle,
        size === 'lg' && styles.largeStyle,
        disabled && styles.disableStyle
      ]}
      onPress={onPress}
      disabled={disabled}
    >
      <Text
       style={[
        styles.commonTextStyle
       ]}
      >{text}</Text>
    </Pressable>
  )
}

export default Button

const styles = StyleSheet.create({
  commonStyle:{
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 8,
    borderRadius: 4,
    backgroundColor:COLOR.primary.blue500
  },
  commonTextStyle:{
    ...FONT.txtSm,
    color:COLOR.mono.white
  },
  secondaryStyle:{},
  secondaryWhiteStyle:{},
  negativeStyle:{},
  bottomBardStyle:{},
  largeStyle:{},
  disableStyle:{
    backgroundColor:COLOR.gray.g200
  }
})