import * as React from 'react'

import type { ImageAvatarProps } from '../types'
import { Image } from 'react-native'

const ImageAvatar: React.FC<ImageAvatarProps> = ({ uri = '', headers, size }) => {
  return <Image style={size} source={{ uri, headers }} />
}

export default ImageAvatar
