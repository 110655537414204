import * as React from 'react'

import { COLOR } from '@rocket-mono/libs'

import Context from './context'
import type { DialogMessageType, ProviderProps } from './types'
import { Platform, StyleSheet } from 'react-native'
import { View, DialogView, DialogBottomView } from '@rocket-mono/foundations'

export const ModalDialogProvider = ({ children }: ProviderProps) => {
  const [dialogMessage, setDialogMessage] = React.useState<DialogMessageType>()
  // const { show, hide } = useBackdrop()

  const showDialogMessage = React.useCallback((dialogMessage: DialogMessageType) => {
    // if (Platform.OS !== 'ios') {
    //   show('modal-dialog')
    // }
    setDialogMessage(dialogMessage)
  }, [])

  const hideDialogMessage = React.useCallback(() => {
    // if (Platform.OS !== 'ios') {
    //   hide('modal-dialog')
    // }
    setDialogMessage(undefined)
  }, [])

  const backdrop = React.useMemo(() => {
    if (dialogMessage === undefined) return false
    if (dialogMessage.backdrop === undefined) return true
    return dialogMessage.backdrop
  }, [dialogMessage])

  React.useEffect(() => {
    if (dialogMessage && dialogMessage.duration) {
      setTimeout(hideDialogMessage, dialogMessage.duration)
    }
  }, [dialogMessage])

  return (
    <Context.Provider value={{ showDialogMessage, hideDialogMessage }}>
      {children}
      {!!dialogMessage && (dialogMessage.type === 'CENTER' || dialogMessage.type === undefined) && (
        <DialogView
          {...dialogMessage}
          visible={!!dialogMessage}
          onClose={hideDialogMessage}
          onDismiss={hideDialogMessage}
        />
      )}
      {!!dialogMessage && dialogMessage.type === 'BOTTOM' && (
        <DialogBottomView
          {...dialogMessage}
          visible={!!dialogMessage}
          onClose={hideDialogMessage}
          onDismiss={hideDialogMessage}
        />
      )}
      {dialogMessage && Platform.OS === 'ios' ? <View style={styles.dimmedCover}></View> : <></>}
      {dialogMessage && backdrop && (
        <View
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            backgroundColor: COLOR.mono.black,
            opacity: 0.7,
            zIndex: 999,
          }}
        />
      )}
    </Context.Provider>
  )
}

export * from './hooks'

const styles = StyleSheet.create({
  dimmedCover: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 99,
    backgroundColor: COLOR.dimmed.a40,
  },
})
