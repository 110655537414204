import React from 'react'
import { useSearchParams } from 'react-router-dom'

// Core viewer
import { Viewer, Worker } from '@react-pdf-viewer/core'

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'

const ANONYMOUS_TOKEN =
  'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJjYW1wYWlnbi1sZXR0ZXIiLCJyb2xlcyI6W3sibmFtZSI6IlJPTEVfQklaX0FOT05ZTU9VUyJ9XSwiaXNzIjoicm9ja2V0LWNhbXBhaWduIiwiaWF0IjoxNzExNDM4MDAxLCJleHAiOjE5MTIwNDI4MDF9.rV04f06mI0zcpF66yxFI2siSWq3bvb9j_q5goALa7W8'

const IntroducePage: React.FC = () => {
  const [searchParams] = useSearchParams()
  const filePath = searchParams.get('filePath')

  const [pdfUrl, setPdfUrl] = React.useState<string>()

  const fetchPdf = React.useCallback(async (path: string | null) => {
    const uploadedPath = path === null ? 'beta-signpad-temp/2024년 관리단 정기집회 최종.pdf' : path
    const response = await fetch(
      `https://rocket-v2-file.develop.gitops.mycroft.kr/api/files/presigned-url?uploadedPath=${uploadedPath}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Auth-Token': ANONYMOUS_TOKEN,
        },
      },
    )
    const data = await response.text()
    setPdfUrl(data)
  }, [])

  React.useEffect(() => {
    fetchPdf(filePath)
  }, [])

  return (
    <>
      {pdfUrl ? (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.js">
          <Viewer fileUrl={pdfUrl} />
        </Worker>
      ) : (
        ''
      )}
    </>
  )
}

export default IntroducePage
