import React from 'react'
import { COLOR, FONT } from '@rocket-mono/libs'
import { View, Text } from 'react-native'

interface Props {
  placeholder: string
  /**
   * onSign callback
   * @param data base64 encoded png image
   */
  onSign: (data: string) => void
}

import SignatureCanvas from 'react-signature-canvas'

type SignatureCanvas = {
  isEmpty: () => boolean
  toDataURL(
    mimeType: string,
    encoderOptions: {
      [key: string]: any
    },
  )
}

const Signpad: React.FC<Props> = ({ placeholder, onSign }) => {
  const sigCanvas = React.useRef<SignatureCanvas>(null)

  const [empty, setEmpty] = React.useState(true)

  React.useEffect(() => {
    console.debug('sigCanvas: ', sigCanvas.current?.isEmpty())
  }, [sigCanvas])
  return (
    <View
      style={{
        // centering the content
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 10,
        paddingBottom: 20,
      }}
    >
      {empty ? (
        <View // Transparent overlay for the signature
          style={{
            position: 'absolute',
            flex: 1,
            width: '100%',
            height: '100%',
            zIndex: 10,
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          pointerEvents="none"
        >
          <Text
            style={{
              ...FONT.txtMdBold,
              color: COLOR.gray.g400,
            }}
          >
            {placeholder}
          </Text>
        </View>
      ) : null}
      <View
        style={{
          borderRadius: 1,
          backgroundColor: 'rgba(246, 248, 249, 1)',
        }}
      >
        <SignatureCanvas
          ref={sigCanvas}
          penColor="black"
          backgroundColor={'rgba(255, 255, 255, 0)'} // respect COLOR.gray.g050
          canvasProps={{
            width: 335,
            height: 192,
            className: 'sigCanvas',
            style: {
              zIndex: 5,
            },
          }}
          onBegin={() => {
            if (sigCanvas.current?.isEmpty()) setEmpty(false)
          }}
          onEnd={() => {
            onSign(sigCanvas.current?.toDataURL('image/png', {}))
          }}
          clearOnResize={false}
        />
      </View>
    </View>
  )
}

export { Signpad }
