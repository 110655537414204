import React from 'react'

import { createBrowserRouter } from 'react-router-dom'
import { Outlet } from 'react-router-dom'
import { LetterDuplicatedErrorPage, LetterExpiredErrorPage } from './pages/Error'
import { LetterAgreementPage, LetterSubmittedPage } from './pages/Letter'

import RootProvider from './providers'
import IntroducePage from './pages/Introduce'

const router = createBrowserRouter([
  {
    path: '',
    element: (
      <RootProvider>
        <Outlet />
      </RootProvider>
    ),
    children: [
      {
        path: '',
        element: <LetterExpiredErrorPage />,
      },
      {
        path: '/letters/:letterId',
        element: <LetterAgreementPage />,
      },
      {
        path: '/expired',
        element: <LetterExpiredErrorPage />,
      },
      {
        path: '/duplicated',
        element: <LetterDuplicatedErrorPage />,
      },
      {
        path: '/submitted',
        element: <LetterSubmittedPage />,
      },
    ],
  },
  {
    path: 'introduce',
    element: <IntroducePage />,
  }
])

export default router
