import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ProjectMember, type Project } from '@rocket-mono/types'
import { useToast } from '@rocket/components'

import type { ProviderProps } from './types'
import { useAstro } from '../../AstroProvider'
import Context from './context'
import { useCurrentUser } from '../../CurrentUserProvider'
import { useSubscription } from '../../StompProvider'
import { useTranslation } from 'react-i18next'
import { useWork } from '../WorkProvider'

export const WorkProjectProvider: React.FC<ProviderProps> = ({
  projectId,
  onUnauthorized,
  onDeleted,
  children,
}: ProviderProps) => {
  const { t } = useTranslation()
  const { astro } = useAstro()
  const { currentUser } = useCurrentUser()
  const { showToastMessage } = useToast()

  const { fetchProjectList } = useWork()
  const [currentProjectId, setCurrentProjectId] = useState<string>()
  const [currentProject, setCurrentProject] = useState<Project | null>()
  const [projectMemberList, setProjectMemberList] = useState<ProjectMember[] | null>()

  const currentProjectMember = useMemo(() => {
    if (projectMemberList === null || projectMemberList === undefined) return null
    return projectMemberList.find(({ userId }) => userId === String(currentUser.id)) ?? null
  }, [currentUser, projectMemberList])

  const authorized = useMemo(() => {
    if (currentProject && projectMemberList && currentProjectMember === null) {
      return false
    } else if (currentProject && projectMemberList && currentProjectMember) {
      return true
    } else {
      return undefined
    }
  }, [currentProject, projectMemberList, currentProjectMember])

  const fetchProject = useCallback((projectId: string) => {
    console.log('fetchProject', projectId)
    fetchProjectList()
    astro
      .readProject(projectId)
      .then(setCurrentProject)
      .catch((e) => {
        console.error('fetchProject', e)
        setCurrentProject(null)
      })
    astro
      .readProjectMemberList(projectId, false, '&')
      .then(setProjectMemberList)
      .catch(() => setProjectMemberList(null))
  }, [])

  useEffect(() => {
    const id = currentProjectId ?? projectId

    if (id !== undefined) fetchProject(id)
    else {
      setCurrentProject(null)
      setProjectMemberList(null)
    }
  }, [projectId, currentProjectId])

  useEffect(() => {
    if (authorized !== undefined && authorized === false) {
      const duration = 1500
      // showToastMessage({
      //   message: t('workboardmemberview.toast.nopermission'),
      //   viewPosition: 'TOP_CENTER',
      //   duration,
      // })
      setTimeout(() => onUnauthorized(), duration)
    }
  }, [authorized])

  useEffect(() => {
    if (currentProject?.deleted) {
      onDeleted()
    }
  }, [currentProject])

  // useSubscription(`/subscribe/${currentUser.id}/project`, ({ body }) => {
  //   console.log(`useSubscription - /subscribe/${currentUser.id}/project`, body, projectId, projectId === body)
  //   if (projectId === body) fetchProject(projectId)
  // })

  useSubscription([`/subscribe/${currentUser.id}/project`, `/subscribe/project/${projectId}/update`], ({ body }) => {
    console.log(`useSubscription - /subscribe/${currentUser.id}/project`, body, projectId, projectId === body)
    console.log(`useSubscription - /subscribe/project/${projectId}/update`, body)
    if (projectId === body) fetchProject(projectId)
  })

  useEffect(() => {
    console.debug('provider-WorkProjectProvider', { projectId, currentProject, projectMemberList })
  }, [projectId, currentProject, projectMemberList])

  if (currentProject === undefined || projectMemberList === undefined) return null

  return (
    <Context.Provider
      value={{ projectId, currentProject, currentProjectMember, projectMemberList, setCurrentProjectId }}
    >
      {children}
    </Context.Provider>
  )
}

export * from './hooks'
