import * as React from 'react'

import '@toast-ui/editor/dist/toastui-editor.css'
import { Editor as ToastEditor } from '@toast-ui/react-editor'

import { Props } from './types'
import { useTranslation } from 'react-i18next'

const Editor: React.FC<Props> = ({ initialValue, placeholder, onChangeContent, onLoadFile }) => {
  const { i18n } = useTranslation()
  const editorRef = React.useRef<ToastEditor>(null)

  const handleChange = React.useCallback(() => {
    if (editorRef.current && onChangeContent) onChangeContent(editorRef.current?.getInstance().getHTML())
  }, [editorRef.current, onChangeContent])

  return (
    <ToastEditor
      ref={editorRef}
      hideModeSwitch
      initialEditType="wysiwyg"
      usageStatistics={false}
      language={i18n.language}
      initialValue={initialValue}
      placeholder={placeholder}
      onChange={handleChange}
      hooks={{
        addImageBlobHook(blob, callback) {
          console.log('addImageBlobHook', blob)
          onLoadFile && onLoadFile(blob).then(callback)
        },
      }}
    />
  )
}

export default Editor
