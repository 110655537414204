import * as React from 'react'
import { getRealColor } from '@rocket-mono/libs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Props } from './types'

const FAIcon: React.FC<Props> = ({ iconName, size, color }) => {
  return <FontAwesomeIcon icon={iconName} size={size} color={getRealColor(color ?? 'mono.black')} />
}

export default FAIcon
