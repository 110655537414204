import React from 'react'

import Context from './context'
import { FieldError, SigningCampaignProviderProps, SigningFills, SigningPrefills } from './types'

import { useAstro } from '@rocket-mono/providers'
import { Campaign, CampaignLetter, SigningForm, SigningFormField, SigningFormState } from '@rocket-mono/types'

export const SigningCampaignProvider = ({ children }: SigningCampaignProviderProps) => {
  const { astro } = useAstro()

  const [formState, setFormState] = React.useState<SigningFormState>('READY')

  const [fills, setFills] = React.useState<SigningFills>({})

  const [letter, setLetter] = React.useState<CampaignLetter>({
    id: 'qwertyuiopasdfghjklzxcvbnm',
    campaignId: '1234',
    customerId: '1234',
    formId: '1234',
    isRead: false,
    link: 'AABBCCDD',
    token: '',
  })

  const [campaign, setCampaign] = React.useState<Campaign>({
    title: '관리단 집회 의결권 위임장',
    description: '전자 서명을 진행합니다.',
    relatedDomain: 'https://www.google.com',
    relatedDomainId: '1234',
    status: 'PENDING',
    sequenceNumber: 1,
    startAt: new Date(),
    endAt: new Date(),
  })

  const [form, setForm] = React.useState<SigningForm>({
    title: '전자 서명',
    campaignId: '1234',
    customerIds: [],
    lang: 'en',
  })

  const [fields, setFields] = React.useState<SigningFormField[]>([])

  // TODO: prefills 있을 경우 처리방법 결정돼야 함
  const [prefills, setPrefills] = React.useState<SigningPrefills>({})

  const createFormSubmission = React.useCallback(
    (formId: string, customerId: string, fills: any) => {
      return fetch(`${astro.option.formBaseUrl}/api/form-submissions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Auth-Token': letter.token ?? '',
        },
        body: JSON.stringify({
          formId,
          customerId,
          campaignLetterId: letter.id,
          fieldSubmissions: fills,
        }),
      }).then((res) => res.json())
    },
    [letter],
  )

  const submit = React.useCallback(() => {
    setFormState('PENDING')
    console.debug({
      letter,
      fills,
    })
    // FIXME: Astro의 HttpHeaders 관련해 토큰을 동적으로 변경할 수 없어 별도의 함수로 작성됨

    createFormSubmission(letter.formId, letter.customerId, Object.values(fills))
      .then(() => {
        setFormState('SUBMITTED')
      })
      .catch(() => {
        setFormState('REJECTED')
      })
  }, [fills, letter])

  const isInput = (field: SigningFormField) => {
    return field.type === 'TEXT' || field.type === 'CHECKBOX'
  }

  const [errors, setErrors] = React.useState<{
    [key: string]: FieldError
  }>({})

  // FIXME: validate form fields
  const validate = React.useCallback(() => {
    // check all fills has values
    const notFilled = fields?.filter((element) => {
      return isInput(element) && element.isRequired && !fills[element.id]?.value
    })

    console.debug('validate: ', { fills, notFilled })

    // change notFilled to error dictionary
    const newErrors: {
      [key: string]: FieldError
    } = {}
    notFilled?.forEach((element) => {
      newErrors[element.id] = {
        type: 'not-filled',
        message: '필수 입력 항목입니다.',
      }
    })

    console.debug('validation result: ', { newErrors })

    setErrors(newErrors)

    return notFilled.length === 0
  }, [fills, fields])

  const disabled = React.useMemo(() => {
    const notFilled = fields.filter((element) => {
      return isInput(element) && element.isRequired && !fills[element.id]?.value
    })
    return notFilled.length > 0
  }, [fills, fields])

  const update = React.useCallback(
    (field: SigningFormField, value) => {
      console.debug('update: ', { field, value })
      setFills((prev) => ({
        ...prev,
        [field.id]: {
          field,
          value,
        },
      }))
    },
    [fills],
  )

  // FIXME: Astro의 HttpHeaders 관련해 토큰을 동적으로 변경할 수 없어 별도의 함수로 작성됨
  const readCampaign = async (token: string, campaignId: string) => {
    return fetch(`${astro.option.campaignBaseUrl}/api/campaigns/${campaignId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Auth-Token': token,
      },
    }).then((res) => res.json())
  }

  // FIXME: Astro의 HttpHeaders 관련해 토큰을 동적으로 변경할 수 없어 별도의 함수로 작성됨
  const readFormFieldList = async (token: string, formId: string) => {
    return fetch(`${astro.option.formBaseUrl}/api/form-fields?formId=${formId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Auth-Token': token,
      },
    }).then((res) => res.json())
  }

  // FIXME: Astro의 HttpHeaders 관련해 토큰을 동적으로 변경할 수 없어 별도의 함수로 작성됨
  const readPrerender = React.useCallback(
    async (token: string, formId: string) => {
      return fetch(`${astro.option.formBaseUrl}/api/forms/${formId}/prerender`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Auth-Token': token,
        },
        body: JSON.stringify(fills),
      }).then((res) => res.json())
    },
    [fills],
  )

  const fetchLetter = React.useCallback(
    async (letterId) => {
      const fetched = await astro.readCampaignLetter(letterId).catch((err) => {
        setFormState('ERROR')
      })
      setLetter(fetched)

      if (!fetched.token) {
        // ANCHOR: 프론트엔드에서의 에러 상태 제어
        if (fetched.isExpired) {
          setFormState('DUPLICATED')
        } else {
          setFormState('ERROR')
        }
        return
      }

      // FIXME: Astro의 HttpHeaders 관련해 토큰을 동적으로 변경할 수 없어 별도의 함수로 작성됨
      const fetchedForm = await readForm(fetched.token, fetched.formId).catch((err) => {
        setFormState('ERROR')
      })
      setForm(fetchedForm)

      // FIXME: Astro의 HttpHeaders 관련해 토큰을 동적으로 변경할 수 없어 별도의 함수로 작성됨
      const fetchedCampaign = await readCampaign(fetched.token, fetched.campaignId).catch((err) => {
        setFormState('ERROR')
      })
      setCampaign(fetchedCampaign)

      if (fetchedCampaign.startAt && fetchedCampaign.endAt) {
        const now = new Date()
        const startAt = new Date(fetchedCampaign.startAt)
        const endAt = new Date(fetchedCampaign.endAt)
        if (now < startAt || now > endAt) {
          setFormState('ERROR')
          return
        }
      }

      if (fetchedCampaign.endAt) {
        const now = new Date()
        const endAt = new Date(fetchedCampaign.endAt)
        if (now > endAt) {
          setFormState('ERROR')
          return
        }
      }

      // FIXME: Astro의 HttpHeaders 관련해 토큰을 동적으로 변경할 수 없어 별도의 함수로 작성됨
      const fetchedFields = await readFormFieldList(fetched.token, fetched.formId).catch((err) => {
        setFormState('ERROR')
      })

      // merge prefills to field defaultValue
      fetchedFields.map((field) => {
        if (fetched.prefills && fetched.prefills[field.id]) {
          field.defaultValue = fetched.prefills[field.id]
        }
      })
      setFields(() => [...fetchedFields])
      fetchedFields.map((field) => {
        if (field.defaultValue) {
          if (field.isEditable) {
            update(field, '')
          } else {
            update(field, field.defaultValue)
          }
        }
      })

      // update prefills
      if (fetched.prefills) {
        setPrefills(fetched.prefills)
      }

      console.debug('fetchLetter: ', { fetchedFields })
    },
    [letter, setLetter],
  )

  const readForm = async (token: string, formId: string) => {
    return fetch(`${astro.option.formBaseUrl}/api/forms/${formId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Auth-Token': token,
      },
    }).then((res) => res.json())
  }

  const readFormFieldPrerender = async (token: string, formId: string, fieldId: string) => {
    const fieldSubmissions = Object.values(fills)
    for (const submission of fieldSubmissions) {
      if (!submission.value) submission.value = submission.field.defaultValue ?? ''
    }
    return fetch(`${astro.option.formBaseUrl}/api/forms/${formId}/fields/${fieldId}/prerender`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Auth-Token': token,
      },
      body: JSON.stringify({
        formId: letter.formId,
        customerId: letter.customerId,
        fieldSubmissions,
      }),
    }).then((res) => res.json())
  }

  const fetchPrerender = React.useCallback(
    async (letterId: string, formId: string, fieldId: string) => {
      const fetched = await astro.readCampaignLetter(letterId).catch((err) => {
        setFormState('ERROR')
      })
      setLetter(fetched)

      // FIXME: Astro의 HttpHeaders 관련해 토큰을 동적으로 변경할 수 없어 별도의 함수로 작성됨
      const prerender = await readFormFieldPrerender(fetched.token, formId, fieldId).catch((err) => {
        setFormState('ERROR')
      })
      console.debug('fetchPrerender: ', { prerender })
      return prerender
    },
    [letter, setLetter, fills],
  )

  return (
    <Context.Provider
      value={{
        letter,
        campaign,
        form,
        fields,
        prefills,
        fills,
        update,
        validate,
        submit,
        formState,
        fetchLetter,
        fetchPrerender,
        errors,
        disabled,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export * from './hooks'
export * from './types'

export default SigningCampaignProvider
