import type { GroupPosition, Project, ProjectMemberAuth, ProjectPosition } from '@rocket-mono/types'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon } from '@rocket-atoms/icon'
import { addItemAtIndex, changeItemAtIndex, removeItemAtIndex, replaceItemAtIndex } from '@rocket-mono/libs'
import { Template } from '@rocket/types'
import { useAstro } from '../../AstroProvider'
import { useCurrentUser } from '../../CurrentUserProvider'
import { useModalDialog } from '../../ModalDialogProvider'
import { useSubscription } from '../../StompProvider'
import storage from '../../storage'
import Context from './context'
import type { BookmarkType, GroupType, ProjectType, ProviderProps, UpdateProjectPositionType } from './types'

export const WorkProvider: React.FC<ProviderProps> = ({ children }: ProviderProps) => {
  const { t } = useTranslation()
  const { astro } = useAstro()
  const { currentUser } = useCurrentUser()

  const { showDialogMessage, hideDialogMessage } = useModalDialog()

  const [groupList, setGroupList] = useState<GroupType[]>()
  const [positionList, setPositionList] = useState<ProjectPosition[]>()
  const [projectList, setProjectList] = useState<ProjectType[]>()
  const [archiveList, setArchiveList] = useState<ProjectType[]>()
  const [guestList, setGuestList] = useState<ProjectType[]>()
  const [templateList, setTemplateList] = useState<Template[]>()
  const [favoriteList, setFavoriteList] = useState<BookmarkType[]>()
  const [recentList, setRecentList] = useState<Project[]>([])
  const storageGuideKey = `${currentUser.id}-work-guide`
  const storageTemplateKey = `${currentUser.id}-template-guide`
  const storageProjectKey = `${currentUser.id}-projectList`
  const storageArchiveKey = `${currentUser.id}-archiveList`
  const storageGuestKey = `${currentUser.id}-guestList`
  const storageRecentKey = `${currentUser.id}-recentList`
  const storageBookmarkKey = `${currentUser.id}-bookmark`

  const [isWorkGuide, setIsWorkGuide] = useState(false)
  const [isTemplateGuide, setIsTemplateGuide] = useState(false)

  const [newMemberInviteModal, setNewMemberInviteModal] = useState<string>()

  const closeWorkGuide = useCallback(() => {
    storage
      .save({ key: storageGuideKey, data: projectList?.length ?? 0 })
      .then(() => setIsWorkGuide(false))
      .catch((res) => console.error('closeWorkGuide', res))
  }, [projectList])

  const openTemplateGuide = useCallback(() => {
    storage
      .save({ key: storageTemplateKey, data: true })
      .then(() => setIsTemplateGuide(true))
      .catch((res) => console.error('closeWorkGuide', res))
  }, [])

  const closeTemplateGuide = useCallback(() => {
    storage
      .save({ key: storageTemplateKey, data: false })
      .then(() => setIsTemplateGuide(false))
      .catch((res) => console.error('closeWorkGuide', res))
  }, [])

  useEffect(() => {
    storage
      .load({ key: storageTemplateKey })
      .then((ret: boolean) => setIsTemplateGuide(ret))
      .catch(() => setIsTemplateGuide(true))
  }, [])

  useEffect(() => {
    storage
      .load({ key: storageGuideKey })
      .then((ret: number) => {
        if (projectList && [0, 2].includes(projectList.length) && ret < projectList.length) {
          setIsWorkGuide(true)
        }
      })
      .catch(() => {
        if (projectList?.length === 0 || projectList?.length === 2) setIsWorkGuide(true)
      })

    return () => {
      setIsWorkGuide(false)
    }
  }, [storageGuideKey, projectList])

  useEffect(() => {
    if (favoriteList !== undefined) {
      storage.save({ key: storageBookmarkKey, data: favoriteList })
    }
  }, [favoriteList])

  const updateGroupList = useCallback((group: GroupType) => {
    setGroupList((prev) => {
      if (prev === undefined) return prev
      const idx = prev.findIndex(({ no }) => no === group.no)
      return idx < 0 ? [...prev, group] : replaceItemAtIndex(prev, idx, group)
    })
  }, [])

  const changeGroupList = useCallback(
    (startIndex: number, endIndex: number) => {
      if (groupList) {
        const list = changeItemAtIndex(groupList, startIndex, endIndex)
        setGroupList(list)
        astro.updateGroupPositionList(list.map((o, groupOrder) => ({ ...o, groupOrder })))
      }
    },
    [groupList],
  )

  const changeFavoriteList = useCallback((startIndex: number, endIndex: number) => {
    setFavoriteList((prev) => {
      if (prev === undefined) return prev
      const changedList = changeItemAtIndex(prev, startIndex, endIndex).map((o, index) => ({ ...o, index }))
      return changedList
    })
  }, [])

  const createProjectPosition = useCallback(
    (groupId: string, projectId: string, projectRow: number) => {
      if (positionList) {
        const prev = positionList.filter((o) => o.groupId !== groupId)
        const filteredList = positionList
          .filter((o) => o.groupId === groupId)
          .sort((a, b) => a.projectRow - b.projectRow)

        const position = {
          id: '',
          activityId: '',
          groupId,
          projectId,
          projectColumn: 0,
          projectRow,
        }
        const changedList = addItemAtIndex(filteredList, projectRow, position).map((o, projectRow) => ({
          ...o,
          projectColumn: 0,
          projectRow,
        }))
        setPositionList([...prev, ...changedList])

        astro.createGroupProjectPosition({ ...position, projectColumn: 100 }).then((position) => {
          replaceItemAtIndex(changedList, projectRow, { ...position, projectColumn: 0 }).map((o) =>
            astro.updateGroupProjectPosition(o).catch((e) => console.error('updateGroupProjectPosition', e)),
          )
        })
      }
    },
    [positionList],
  )

  const deleteProjectPosition = useCallback(
    (groupId: string, projectId: string) => {
      if (positionList) {
        const prev = positionList.filter((o) => o.groupId !== groupId)
        const filteredList = positionList
          .filter((o) => o.groupId === groupId)
          .sort((a, b) => a.projectRow - b.projectRow)

        const idx = filteredList.findIndex((o) => o.projectId === projectId)
        const changedList = removeItemAtIndex(filteredList, idx)
        setPositionList([...prev, ...changedList])
        astro.deleteGroupProjectPosition(filteredList[idx].id)
      }
    },
    [positionList],
  )

  const updateProjectPositionList = useCallback(
    (from: UpdateProjectPositionType, to: UpdateProjectPositionType) => {
      const changedConvert = (o, projectRow) => ({
        ...o,
        projectColumn: 0,
        projectRow,
      })

      if (positionList && from.groupId === to.groupId) {
        const prev = positionList.filter(({ groupId }) => groupId !== from.groupId)
        const filteredList = positionList
          .filter(({ groupId }) => groupId === from.groupId)
          .sort((a, b) => a.projectRow - b.projectRow)

        const changedList = changeItemAtIndex(filteredList, from.index, to.index).map(changedConvert)
        setPositionList([...prev, ...changedList])
        changedList.map((o) => astro.updateGroupProjectPosition(o))
      } else if (positionList && from.groupId !== to.groupId) {
        const prev = positionList.filter(({ groupId }) => groupId !== from.groupId && groupId !== to.groupId)
        const fromFilteredList = positionList
          .filter(({ groupId }) => groupId === from.groupId)
          .sort((a, b) => a.projectRow - b.projectRow)
        const toFilteredList = positionList
          .filter(({ groupId }) => groupId === to.groupId)
          .sort((a, b) => a.projectRow - b.projectRow)

        const position = { ...fromFilteredList[from.index], groupId: to.groupId }

        const fromChangedList = removeItemAtIndex(fromFilteredList, from.index).map(changedConvert)
        const toChangedList = addItemAtIndex(toFilteredList, to.index, position).map(changedConvert)
        const changedList = [...fromChangedList, ...toChangedList]
        setPositionList([...prev, ...changedList])
        changedList.map((o) => astro.updateGroupProjectPosition(o))
      }
    },
    [positionList],
  )

  const cacheProjectList = useCallback(() => {
    storage
      .load({ key: storageProjectKey })
      .then((ret) => {
        if (ret) setProjectList(ret)
      })
      .catch((err) => {
        console.log('err', err)
      })
    storage
      .load({ key: storageArchiveKey })
      .then((ret) => {
        if (ret) setArchiveList(ret)
      })
      .catch((err) => {
        console.log('err', err)
      })
    storage
      .load({ key: storageGuestKey })
      .then((ret) => {
        if (ret) setGuestList(ret)
      })
      .catch((err) => {
        console.log('err', err)
      })
  }, [])

  const fetchProjectConvert = (res) => {
    const is: ProjectMemberAuth[] = ['MEMBER']
    const auth = res.members.find((member) => member.userId === String(currentUser.id))?.auth
    const shared = auth ? is.includes(auth) : false
    return { ...res, shared, load: false }
  }
  const fetchProject = useCallback(
    (projectId: string) => {
      if (projectList) {
        const idx = projectList.findIndex(({ id }) => id === projectId)
        if (idx >= 0) {
          astro
            .readProject(projectId)
            .then(fetchProjectConvert)
            .then((project) => {
              console.log('fetchProject', project)

              const member = project.members.find(({ userId }) => userId === String(currentUser.id))
              if (project.isArchive || member === undefined) {
                setProjectList(removeItemAtIndex(projectList, idx))
                fetchProjectList()
              } else {
                setProjectList(replaceItemAtIndex(projectList, idx, project))
              }
            })
        }
      }
    },
    [projectList, currentUser],
  )

  const fetchProjectList = useCallback(() => {
    astro
      .readProjectList(false)
      .then((projectList) => {
        return projectList.map(fetchProjectConvert)
      })
      .then((res) => {
        storage.save({ key: storageProjectKey, data: res })
        return res
      })
      .then(setProjectList)
      .catch(() => setProjectList([]))
    astro
      .readProjectList(true)
      .then((projectList) => {
        return projectList.map(fetchProjectConvert)
      })
      .then((res) => {
        storage.save({ key: storageArchiveKey, data: res })
        return res
      })
      .then(setArchiveList)
      .catch(() => setArchiveList([]))
    astro
      .readProjectList(false, 0)
      .then((projectList) => {
        return projectList.map(fetchProjectConvert)
      })
      .then((res) => {
        storage.save({ key: storageGuestKey, data: res })
        return res
      })
      .then(setGuestList)
      .catch(() => setGuestList([]))
  }, [currentUser])

  const fetchGroup = useCallback((group: GroupPosition) => {
    return astro.readGroup(group.groupNo).then(({ name }) => ({ ...group, name, load: true }))
  }, [])

  const deleteGroup = useCallback(
    (groupId: string) => {
      if (groupList && positionList) {
        const duration = 5000
        const prev = positionList.filter((o) => o.groupId !== groupId)
        const filteredList = positionList
          .filter((o) => o.groupId === groupId)
          .sort((a, b) => a.projectRow - b.projectRow)

        setPositionList(prev)

        const groupIdx = groupList.findIndex((o) => String(o.groupNo) === groupId)
        const group = { ...groupList[groupIdx] }
        const prevGroup = removeItemAtIndex(groupList, groupIdx)
        setGroupList(prevGroup)

        const to = setTimeout(() => {
          astro.deleteGroup(groupId, String(group.no))
        }, duration)

        const title = t('workspace.ungrouptoast')
        const subList = [
          {
            icon: 'renew',
            name: t('workspace.ungroupcancel'),
            action: () => {
              clearTimeout(to)
              setPositionList([...prev, ...filteredList])
              setGroupList([...prevGroup, group])
              hideDialogMessage()
            },
          },
        ]
        showDialogMessage({
          backdrop: false,
          type: 'BOTTOM',
          title,
          list: [],
          subList,
          duration,
          cancelText: <Icon name="close" />,
          onCancel: () => {
            hideDialogMessage()
          },
        })
      }
    },
    [groupList, positionList],
  )

  const fetchGroupList = useCallback(() => {
    astro
      .readGroupPositionList()
      .then((res) => res.sort((a, b) => a.groupOrder - b.groupOrder))
      .then((res) => {
        setGroupList(res.map((o) => ({ ...o, groupNo: String(o.groupNo) })))
        return res
      })
      .then((res) => {
        Promise.all(res.map((group) => astro.readGroupProjectPositionList(group.groupNo))).then((r) =>
          setPositionList(r.flatMap((o) => o)),
        )
        return Promise.all(res.map(fetchGroup))
      })
      .then(setGroupList)
  }, [])

  const fetchBookmarkList = useCallback(() => {
    astro
      .readBookmarkList('PROJECT')
      .then((bookmarks) => {
        return Promise.all(
          bookmarks.map((bookmark) => {
            return astro.readProject(bookmark.refKey).then((project) => {
              return { ...bookmark, project }
            })
          }),
        )
      })
      .then((bookmarks) => {
        return storage
          .load({ key: storageBookmarkKey })
          .then((list) => {
            const is = bookmarks.filter(({ refKey }) => !list.map(({ refKey }) => refKey).includes(refKey)).length > 0
            if (is && bookmarks.length === list.length) {
              return bookmarks.map((o, index) => ({ ...o, index }))
            } else {
              return list
            }
          })
          .catch(() => bookmarks.map((o, index) => ({ ...o, index })))
      })
      .then(setFavoriteList)
      .catch(() => setFavoriteList([]))
  }, [])

  const fetchRecentList = useCallback(() => {
    storage
      .load({ key: storageRecentKey })
      .then((ret) => {
        if (ret) setRecentList(ret)
      })
      .catch((err) => {
        console.log('err', err)
      })
  }, [])

  const updateGroupTitle = useCallback(
    (groupId: string, title: string) => {
      const group = groupList?.find((o) => o.groupNo === groupId)
      if (group) {
        astro.updateGroupTitle(groupId, title).then(() => {
          updateGroupList({ ...group, name: title })
        })
      }
    },
    [groupList],
  )

  const mergeProject = useCallback(
    (projectIds: string[]) => {
      astro.createGroup().then(({ no: groupNo, name }) => {
        Promise.all([
          astro.createGroupPosition({
            userNo: String(currentUser.id),
            groupNo: groupNo,
            activityNo: '',
            groupMaxColumn: 0,
            groupMaxRow: 0,
            groupOrder: groupList?.length ?? 0,
          }),
          ...projectIds.map((projectId, projectRow) => {
            return astro.createGroupProjectPosition({
              activityId: '',
              groupId: String(groupNo),
              projectId: projectId,
              projectColumn: 0,
              projectRow,
            })
          }),
        ]).then(([group, ...list]) => {
          setPositionList((prev) => (prev !== undefined ? [...prev, ...list] : list))
          updateGroupList({ ...group, name, load: true })
        })
      })
    },
    [groupList],
  )

  const addFavorite = useCallback(
    async (projectId: string) => {
      const project = await astro.readProject(projectId)
      try {
        const bookmark = await astro.createBookmark('PROJECT', project.id)
        setFavoriteList((prev) => {
          if (prev) {
            return [{ ...bookmark, project }, ...prev]
          } else {
            return [{ ...bookmark, project }]
          }
        })
        return bookmark
      } catch (e) {
        throw e
      }
    },
    [favoriteList],
  )
  const deleteFavorite = useCallback(
    (projectId: string) => {
      if (!favoriteList) return
      const item = favoriteList.find((o) => o.refKey === projectId)
      if (item) {
        astro.deleteBookmark(item.id).then(() => {
          const newList = favoriteList.filter((o) => o.id !== item.id)
          setFavoriteList(newList)
        })
      }
    },
    [favoriteList],
  )
  const addRecent = useCallback(
    (projectId: string) => {
      astro.readProject(projectId).then((project) => {
        if (recentList) {
          const idx = recentList.findIndex((o) => o.id === projectId)
          if (idx < 0) {
            const newList = [project, ...recentList].slice(0, 4)
            setRecentList(newList)
            storage.save({ key: storageRecentKey, data: newList })
          } else {
            const newList = [project, ...recentList.slice(0, idx), ...recentList.slice(idx + 1)].slice(0, 4)
            setRecentList(newList)
            storage.save({ key: storageRecentKey, data: newList })
          }
        } else {
          setRecentList([project])
          storage.save({ key: storageRecentKey, data: [project] })
        }
      })
    },
    [recentList],
  )

  const onNewMemberInviteModal = useCallback((projectId?: string) => {
    setNewMemberInviteModal(projectId)
  }, [])

  useEffect(() => {
    cacheProjectList()
    fetchProjectList()
    fetchGroupList()
    astro.readTemplateList().then(setTemplateList)
    fetchBookmarkList()
    fetchRecentList()
  }, [])

  useSubscription(`/subscribe/${currentUser.id}/project`, () => {
    fetchProjectList()
  })

  useSubscription(projectList?.map(({ id }) => `/subscribe/project/${id}/update`) ?? [], ({ body: projectId }) => {
    fetchProject(projectId)
  })

  useEffect(() => {
    console.debug('provider-WorkProvider')
  }, [])

  return (
    <Context.Provider
      value={{
        templateList,
        groupList,
        positionList,
        projectList,
        archiveList,
        guestList,
        favoriteList,
        fetchProjectList,
        changeFavoriteList,
        recentList,
        updateGroupTitle,
        isWorkGuide,
        closeWorkGuide,
        isTemplateGuide,
        openTemplateGuide,
        closeTemplateGuide,
        mergeProject,
        deleteGroup,
        changeGroupList,
        createProjectPosition,
        updateProjectPositionList,
        deleteProjectPosition,
        addFavorite,
        deleteFavorite,
        addRecent,
        newMemberInviteModal,
        onNewMemberInviteModal,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export * from './hooks'
export type { BookmarkType, GroupType, ProjectExtraType, ProjectType } from './types'
