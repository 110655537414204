import * as React from 'react'
import { View } from 'react-native'

import { COLOR } from '@rocket-mono/libs'

import { IconProfileLabelLeader, IconStateCircle } from '@rocket-atoms/icon'

import { Props } from './types'

import ImageAvatar from './components/image'
import { TextAvatar } from './components/text'

const Avatar: React.FC<Props> = ({
  userName = '',
  profileUrl,
  size,
  isLoading = false,
  isLeader,
  state,
  headers,
  stateIconSize,
}) => {
  const sizeProps = React.useMemo(() => {
    let sizeNum = 52
    switch (size) {
      case 'xxlarge':
        sizeNum = 80
        break
      case 'xlarge':
        sizeNum = 52
        break
      case 'large':
        sizeNum = 40
        break
      case 'medium':
        sizeNum = 36
        break
      case 'small':
        sizeNum = 24
        break
      case 'xsmall':
        sizeNum = 20
        break
      case 'xxsmall':
        sizeNum = 16
        break
      default:
        break
    }

    return { width: sizeNum, height: sizeNum, borderRadius: sizeNum }
  }, [size])
  if (isLoading) return <View style={[sizeProps, { backgroundColor: COLOR.gray.g200 }]} />

  return (
    <View style={{ width: sizeProps.width, height: sizeProps.height }}>
      {profileUrl !== undefined ? (
        <ImageAvatar size={sizeProps} uri={profileUrl} headers={headers} />
      ) : (
        <TextAvatar size={sizeProps} alt={userName} />
      )}
      {isLeader && <IconProfileLabelLeader style={{ position: 'absolute', right: -4, bottom: -4 }} />}
      {!!state && (
        <IconStateCircle style={{ position: 'absolute', right: -2, bottom: -2 }} sizing={stateIconSize} state={state} />
      )}
    </View>
  )
}

export default Avatar
