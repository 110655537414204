import * as React from 'react'

import { DialogView } from '@rocket-mono/foundations'
import { COLOR, FONT } from '@rocket-mono/libs'
import { LoginModal, TermsModal } from '@rocket/components'
import { useTranslation } from 'react-i18next'
import { Modal, Platform, StyleSheet, Text, View } from 'react-native'
import { useAstro } from '../AstroProvider'
import { useCurrentUser } from '../CurrentUserProvider'
import { DialogMessageType } from '../ModalDialogProvider/types'
import { ContextProps } from './types'

export type LOGIN_STATE = 'initial' | 'login.email' | 'signup.email' | 'recovery' | 'recovery.done'

type IncorrectPasswordArg = { lockCount: number; maxCount: number }
type ErrorSymbolArgs = IncorrectPasswordArg
type LoginFail = { errorMessage: string; errorSymbol: string; errorType: string; errorSymbolArgs: ErrorSymbolArgs }
type DialogListType = { name: string; action: () => void }

export const AuthContext = React.createContext<ContextProps | undefined>(undefined)
interface Props {
  children?: JSX.Element | JSX.Element[]
}

export const AuthProvider = ({ children }: Props) => {
  const { astro } = useAstro()
  const { currentUser } = useCurrentUser()

  const { t } = useTranslation()

  const isAnonymous = React.useMemo(() => currentUser.userEmail === null, [currentUser])
  const [visible, setVisible] = React.useState(false)
  const [isTerms, setIsTerms] = React.useState(false)
  const [isPrivacy, setIsPrivacy] = React.useState(false)

  const [termsData, setTermsData] = React.useState<{ title: string; contents: string }>()
  const [privacyData, setPrivacyData] = React.useState<{ title: string; contents: string }>()

  const [loginStateMove, setLoginStateMove] = React.useState<LOGIN_STATE>()
  const [retryLogin, setRetryLogin] = React.useState<boolean>()
  const [dialogMessage, setDialogMessage] = React.useState<DialogMessageType>()

  const showLoginModal = React.useCallback(() => {
    setVisible(true)
  }, [])

  React.useEffect(() => {
    astro.readTerms('pro_terms').then(setTermsData)
    astro.readTerms('pro_privacy').then(setPrivacyData)
  }, [])

  return (
    <AuthContext.Provider value={{ showLoginModal, isAnonymous }}>
      <>
        {children}
        {visible && (
          <LoginModal
            astro={astro}
            whenPressDismiss={() => {
              console.log('whenPressDismiss')
              setVisible(false)
              setLoginStateMove(undefined)
            }}
            whenLoginSuccess={(token) => {
              console.log('login', token)
              localStorage.setItem('authToken', token)
              location.reload()
            }}
            whenLoginFail={(response: Response) => {
              response.json().then((data: LoginFail) => {
                setRetryLogin(undefined)
                let titleRender: (() => JSX.Element) | undefined = undefined
                let messageRender: (() => JSX.Element) | undefined = undefined
                let list: DialogListType[] = []
                let subList: DialogListType[] = []
                let cancelText: string | undefined = undefined
                let onCancel: (() => void) | undefined = undefined
                if (data.errorType === 'NOT_ACCOUNT') {
                  titleRender = () => <Text style={styles.messageTitleText}>{t('signin.notaccount.title')}</Text> // 'No account found'
                  messageRender = () => <Text style={styles.messageText}>{t('signin.notaccount.text')}</Text>
                  list = [
                    {
                      name: t('signin.notaccount.signup'), // 'Sign Up',
                      action: () => {
                        // navigate('/join')
                      },
                    },
                  ]
                  subList = [
                    {
                      name: t('signin.notaccount.retry'), // 'Retry',
                      action: () => {
                        // if (emailPayload) {
                        //   astro.loginByEmail({ type: 'pro', ...emailPayload }).then(onEmailLogin)
                        // }
                        setRetryLogin(true)
                      },
                    },
                  ]
                } else if (data.errorType === 'INCORRECT_PASSWORD') {
                  titleRender = () => <Text style={styles.messageTitleText}>{t('signin.incorrectpassword.title')}</Text> // 'Please Check your ID or Password again'
                  if (data.errorSymbol && data.errorSymbolArgs) {
                    const { lockCount, maxCount } = data.errorSymbolArgs
                    messageRender = () => (
                      <Text style={[styles.messageText, styles.colorRed]}>
                        {t(data.errorSymbol, { lockCount, maxCount })}
                      </Text>
                    )
                  } else {
                    messageRender = () => <Text style={[styles.messageText, styles.colorRed]}>{data.errorMessage}</Text>
                  }
                  list = [
                    {
                      name: t('signin.incorrectpassword.confirm'), // 현기능은 재시도로 변경됨
                      action: () => {
                        // if (emailPayload) {
                        //   astro.loginByEmail({ type: 'pro', ...emailPayload }).then(onEmailLogin)
                        // }
                        setRetryLogin(true)
                      },
                    },
                  ]
                  cancelText = t('signin.incorrectpassword.cancel') // 현기능은 임시 패스워드로 발송으로 변경됨
                  onCancel = () => {
                    // setIsFindPassword(true)
                    setLoginStateMove('recovery')
                  }
                } else if (data.errorType === 'ACCOUNT_LOCKED') {
                  titleRender = () => <Text style={styles.messageTitleText}>{t('signin.accountlocked.title')}</Text> // 'Please Check your ID or Password again'
                  messageRender = () => <Text style={styles.messageText}>{t('signin.accountlocked.text')}</Text>
                  list = [
                    {
                      name: t('signin.accountlocked.findpassword'), // 'Find Password',
                      action: () => {
                        // setIsFindPassword(true)
                        setLoginStateMove('recovery')
                      },
                    },
                  ]
                  subList = [
                    {
                      name: t('signin.accountlocked.retry'), // 현기능은 다른 계정으로 로그인으로 변경됨
                      action: () => {
                        // location.reload()
                      },
                    },
                  ]
                }
                setDialogMessage({
                  title: '',
                  titleRender,
                  messageRender,
                  list,
                  subList,
                  cancelText,
                  onCancel,
                  backdrop: false,
                })
              })
            }}
            onTerms={() => setIsTerms(true)}
            onPrivacy={() => setIsPrivacy(true)}
            dimmed
            isJoinNextLogin
            loginStateMove={loginStateMove}
            retryLogin={retryLogin}
          />
        )}
        {isTerms && (
          <Modal transparent>
            <TermsModal title={termsData?.title} contents={termsData?.contents} onClose={() => setIsTerms(false)} />
          </Modal>
        )}
        {isPrivacy && (
          <Modal transparent>
            <TermsModal
              title={privacyData?.title}
              contents={privacyData?.contents}
              onClose={() => setIsPrivacy(false)}
            />
          </Modal>
        )}
        {dialogMessage ? (
          <Modal transparent>
            <DialogView
              {...dialogMessage}
              visible={!!dialogMessage}
              onClose={() => setDialogMessage(undefined)}
              onDismiss={() => setDialogMessage(undefined)}
            />
            <View
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
                backgroundColor: COLOR.mono.black,
                opacity: 0.7,
                zIndex: 999,
              }}
            />
          </Modal>
        ) : (
          <></>
        )}
      </>
    </AuthContext.Provider>
  )
}

const styles = StyleSheet.create({
  messageTitleText: {
    ...Platform.select({
      web: {
        ...FONT.H5Bold,
        marginBottom: 8,
      },
      native: {
        ...FONT.H6Bold,
        marginBottom: 4,
      },
    }),
    color: COLOR.mono.black,
  },
  messageText: {
    ...Platform.select({
      web: {
        ...FONT.txtMd,
      },
      native: {
        ...FONT.txtSm,
      },
    }),
    color: COLOR.mono.black,
  },
  colorRed: {
    color: COLOR.main.red,
  },
})

export * from './hooks'
